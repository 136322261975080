import React, { useEffect, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa';// Importing file icon from react-icons
import axiosInstance from '../../axiosConfig';
import './Event.css';
import { Link } from 'react-router-dom';
import VideoPlayer from '../VideoPlayer';

const Events = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axiosInstance.get('/events');
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  const handleFileClick = (fileName) => {
    const fileUrl = `${process.env.PUBLIC_URL}/${fileName}`;
    window.open(fileUrl, '_blank'); // Opens the PDF in a new tab
  };

  return (
    <div className='home'>
      <div className='top-heading'>
        <h1>Upcoming Events</h1>
      </div>

      <div className='event-video'>
      <VideoPlayer videoName="EventVideo.mp4" />
      </div>

      <div className='button-container'>
        <button 
          className='btn'
          onClick={() => handleFileClick('TermsAndConditions.pdf')}

        >
          Information and Guideline for Event
        </button>
        <button 
          className='btn'
          onClick={() =>  handleFileClick('GuidelineForRegistration.pdf')}
        >
          Guideline For Registration
        </button>
      </div>

      <div className='event-body'>
        <div className='event-container'>
          <div className='event-header'>
            <div className='event-columns'>Category</div>
            <div className='event-name'>Name</div>
            {/* <div className='event-columns'>Terms & Conditions</div> */}
            <div className='event-columns'>Event Start Date</div>
            {/* <div className='event-columns'>Time</div> */}
            <div className='event-columns'>Registration Start Date</div>
            <div className='event-columns'>Details</div>
          </div>
          {events.map((event, index) => (
            <div key={index} className='event-row'>
              <div className='event-columns'>{event.categoryName}</div>
              <div className='event-name'>{event.name}</div>
              {/* <div className='event-columns'>
                <a className='file-icon' href={`${process.env.REACT_APP_API_BASE_URL}/events/${event.id}`} target="_blank" rel="noopener noreferrer">
                  <FaFilePdf />
                </a>
              </div> */}
              <div className='event-columns'>{event.eventStartDate}</div>
              {/* <div className='event-columns'>{event.time}</div> */}
              <div className='event-columns'>{event.registrationStartDate}</div>
              <div className='event-columns'>
              <Link 
                  to="/events/detail"
                  state={{ event }} // Passing the entire event object
                  className='know-more-button'
                >Know More</Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Events;
