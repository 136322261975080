import React from 'react';
import './HomeTraining.css'; // Import the CSS file
import { Link } from 'react-router-dom';

const trainings = [
  {
    imgSrc: `${process.env.PUBLIC_URL}/academy/T1.png`, // Update with actual image paths
    name: 'Fall HoopzoneCoaching',
    description: 'The Fall Hoopzone clinic is a 12-week program with weekly skill competitions and 5-on-5/3-on-3 games from October to December.'
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/academy/T2.png`, // Update with actual image paths
    name: 'Holiday Basketball Coaching',
    description: 'Our holiday clinic series offers young basketball players a fun, 3-day, 3-hour daily winter program.'
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/academy/T3.png`, // Update with actual image paths
    name: 'Skills Competitions',
    description: 'Compete in citywide Skills Competitions to test your handles, stroke, and speed against other ballers in offensive challenges.'
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/academy/T4.png`, // Update with actual image paths
    name: 'Mini Dribbler Coaching',
    description: 'This program for age 5 focuses on fundamentals, with weeklong camps combining skill development, competitions, guest appearances, and giveaways.'
  }
];

const HomeTraining = () => {
  return (
    <div >
      <div className="home-coaching-heading">
        <h1>Our Trainings</h1>
      </div>
      <div className="home-coaching-cards">
        {trainings.map((training, index) => (
          <div key={index} className="training-card">
            <img src={training.imgSrc} alt={training.name} />
            <h3>{training.name}</h3>
            <p>{training.description}</p>
            {/* <a href="#" className="learn-more-button">Learn More</a> */}
            <Link to="/academy"  className="learn-more-button">Learn More</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeTraining;
