
import React, { useEffect, useState } from 'react';
import './PaymentValidation.css'; // Import your CSS file for styling
import axiosInstance from '../../axiosConfig';
import { toast } from "react-toastify";
import { Auth } from 'aws-amplify';

const PaymentValidation = () => {
    const [pendingPayments, setPendingPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [editPayment, setEditPayment] = useState(null); // State to hold the payment being edited
    const [updateData, setUpdateData] = useState({}); // State for the form inputs

    useEffect(() => {
        const fetchPendingPayments = async () => {
            try {
                const session = await Auth.currentSession();
                const token = session.getIdToken().getJwtToken();
                const response = await axiosInstance.get('/admin/pending/validation',
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                      },
                    });
                setPendingPayments(response.data);
            } catch (err) {
                console.error('Error fetching pending payments:', err);
                setError('Error fetching pending payments');
                toast.error('Error fetching pending payments');
            } finally {
                setLoading(false);
            }
        };

        fetchPendingPayments();
    }, []);

    const handleEditClick = (payment) => {
        setEditPayment(payment);
        setUpdateData({
            transactionId: payment.transactionId,
            paymentDone: payment.paymentDone,
            validationSuccess: payment.validationSuccess,
            validationReason: payment.validationReason || '',
            paymentValidation: payment.paymentValidation,
        });
    };

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        setUpdateData({
            ...updateData,
            [name]: type === 'checkbox' ? e.target.checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(updateData.transactionId.length !== 12){
            toast.warn("Transaction ID must contain exactly 12 characters.");
            return;
        }
        try {
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
            const response = await axiosInstance.put(`/admin/update-payment/${editPayment.id}`, updateData,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                });

            setPendingPayments(pendingPayments.map(payment => 
                payment.id === editPayment.id ? { ...payment, ...updateData } : payment
            ));
            setEditPayment(null); 
            toast.success("Details updated successfully");
        } catch (err) {
            console.error('Error updating payment:', err);
            setError('Error updating payment');
            toast.error('Error updating payment:', err);
        }
    };

    if (loading) {
        return (
          <div className="loading-overlay">
            <div className="loading-text">Loading, please wait...</div>
          </div>
        );
      }
    

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="payment-validation-container">
            <h2>Pending Payment Validations</h2>
            {editPayment && (
                <form onSubmit={handleSubmit}>
                    <h3>Edit Payment Validation</h3>
                    <label>
                        Transaction ID:<span style={{fontSize:"12px", color:"red"}}>(Only update if the current Transaction ID is incorrect)</span>
                        <input
                            type="text"
                            name="transactionId"
                            value={updateData.transactionId}
                            onChange={handleInputChange}
                            required
                        />
                    </label>
                    <label>
                        Payment Done:<span style={{fontSize:"12px", color:"red"}}>(Uncheck if the user has not completed the payment)</span>
                        <input
                            type="checkbox"
                            name="paymentDone"
                            checked={updateData.paymentDone}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label>
                        Validation Done:<span style={{fontSize:"12px", color:"red"}}>(Check this box if the payment has been validated by an admin)</span>
                        <input
                            type="checkbox"
                            name="paymentValidation"
                            checked={updateData.paymentValidation}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label>
                        Validation Success:<span style={{fontSize:"12px", color:"red"}}>(Check if the Transaction ID and payment amount are correct)</span>
                        <input 
                            type="checkbox"
                            name="validationSuccess"
                            checked={updateData.validationSuccess}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label>
                        Validation Reason:<span style={{fontSize:"12px", color:"red"}}>(Provide reasons for validation success or failure)</span>
                        <input
                            type="text"
                            name="validationReason"
                            value={updateData.validationReason}
                            onChange={handleInputChange}
                        />
                    </label>
                    
                    <button type="submit">Update Payment</button>
                    <button type="button" onClick={() => setEditPayment(null)}>Cancel</button>
                </form>
            )}
            <table className="payment-validation-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Transaction ID</th>
                        <th>Event Category</th>
                        <th>Team Name</th>
                        <th>Registration/Payment Time</th>
                        <th>Payment Done</th>
                        <th>Validation Success</th>
                        <th>Validation Reason</th>
                        <th>Validation Done</th>
                        <th>Edit</th> {/* New Edit Column */}
                    </tr>
                </thead>
                <tbody>
                    {pendingPayments.map((payment) => (
                        <tr key={payment.id}>
                            <td>{payment.id}</td>
                            <td>{payment.transactionId}</td>
                            <td>{payment.eventTeam.event.category.category}</td>
                            <td>{payment.eventTeam.team.name}</td>
                            <td>{new Date(payment.paymentTimestamp).toLocaleString()}</td>
                            <td>{payment.paymentDone ? 'Yes' : 'No'}</td>
                            <td>{payment.validationSuccess ? 'Yes' : 'No'}</td>
                            <td>{payment.validationReason || 'N/A'}</td>
                            <td>{payment.paymentValidation ? 'Yes' : 'No'}</td>
                            <td>
                                <button onClick={() => handleEditClick(payment)}>Edit</button> {/* Edit Button */}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PaymentValidation;

