import React from "react";

const CoachingProgram = () => {
  const imgsrc1 = `${process.env.PUBLIC_URL}/academy/T1.png`;
  const imgsrc2 = `${process.env.PUBLIC_URL}/academy/T2.png`;
  const imgsrc3 = `${process.env.PUBLIC_URL}/academy/T3.png`;
  return (
    <div>
      <div className="academy-sub-container">
        <div className="academy-image">
          <img className="gameday" src={imgsrc1} alt="MBA Logo" />
          <img className="gameday" src={imgsrc2} alt="MBA Logo" />
          <img className="gameday" src={imgsrc3} alt="MBA Logo" />
        </div>
        <div className="academy-sub-content">
          <h3>Coaching Programs</h3>
          <ul>
            <li>
              Fall Hoopzone Coaching – The Fall Hoopzone clinic is a 12-week
              program that meets one day per week between the months of
              October-December. Players compete against one another in skill
              development competitions and 5 on 5 / 3 on 3 games.
            </li>
            <li>
              Holiday Basketball Coaching – Our holiday clinic series is a fun
              program to break up the winter for young basketball players.
              Holiday clinics are typically 3-day programs, 3 hours each day.
            </li>
            <li>
              Winter Basketball Coaching – Where players develop skills and take
              part in fun competitions. Most spring break clinics are
              Monday-Friday, 6:00-8:30 each day.
            </li>
            <li>
              Mini Dribbler Coaching – This program is for youngsters, age 5.
              The aspiring stars meet each week with our program director to
              work on basic basketball fundamentals.
            </li>
            <li>
              Summer Camps – Summer basketball camps are fun and consist of
              skill development drills, competitions, and games. All
              participants receive a t-shirt, basketball. The standard summer
              camp schedule. Campers are encouraged to bring lunch for the break
              in the middle of each day.
            </li>
          </ul>
          <p>
            Through our program of teams, our coaching staff is dedicated to
            improving the skills of each individual player and giving them the
            opportunity to showcase their skills against some of the best
            competition.
          </p>
          <p>
            We have also planned and hosted tournaments that have attracted some
            of the best club programs. Our staff manages and organizes host
            facilities, registration, referees, and scheduling.
          </p>
          <p>
            We also organize and run Skills Competitions, 5x5, and 3x3
            Tournaments.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CoachingProgram;
