import React, { useEffect, useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';

import Home from './components/home/Home';
import awsExports from './aws-exports';
import AboutMBA from './components/aboutMba/AboutMBA';
import Events from './components/event/Events';
import MemberForm from './components/addMembers/MemberForm';
import Members from './components/aboutMba/member/Members';
import Organisation from './components/aboutMba/Organisation';
import Navbar from './components/common/Navbar';
import Contact from './components/contact/Contact';
import Gallery from './components/gallery/Gallery';
import Academy from './components/academy/Academy';
import Footer from './components/common/Footer';
import Display from './components/scoreBoard/Display';
import Controller from './components/scoreBoard/Controller';
import { TimerProvider } from './components/scoreBoard/TimerContext';
import RegisterPage from './components/auth/RegisterPage';
import ValidatePage from './components/auth/ValidatePage';
import LoginPage from './components/auth/LoginPage';
import AdminPage from './components/admin/AdminPagr';
import ProtectedRoute from './components/auth/ProtectedRoute';
import RoleProtectedRoute from './components/auth/RoleProtectedRoute';
import RedirectAuthenticated from './components/auth/RedirectAuthenticated';
import CreateEvents from './components/event/CreateEvents';
import FileUploadWithMetadata from './components/FileUploadWithMetadata';
import UnauthorizedPage from './components/UnauthorizedPage';
import Profile from './components/proflie/Profile';
import EventRegisterForm from './components/eventRegister/EventRegisterForm';
import EventDetails from './components/event/EventDetails';
import AddressPage from './components/event/address/AddressPage';
import AgeCategory from './components/event/category/AgeCategory';
import PlayerRegistrationForm from './components/eventRegister/PlayerRegistrationForm';
import PaymentPage from './components/payment/PaymentPage';
import ScrollToTop from './components/common/ScrollToTop';
import UserDashboard from './components/userDashboard/UserDashboard';
import TeamDetail from './components/userDashboard/TeamDetail';
import AllTeams from './components/admin/AllTeams';
import PaymentValidation from './components/admin/PaymentValidation';
import ForgetPasswordPage from './components/auth/ForgetPasswordPage';

// Custom hook to get current path
const useCurrentPath = () => {
  const location = useLocation();
  return location.pathname;
};

Amplify.configure(awsExports);

const AppContent = () => {
  const currentPath = useCurrentPath();
  const isFullScreenRoute = currentPath === '/display';
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        await Auth.currentSession();
        setIsAuthenticated(true);
      } catch {
        setIsAuthenticated(false);
      }
    };

    checkAuthStatus();
  }, []);

  function updateAuthStatus(authStatus) {
    setIsAuthenticated(authStatus);
  }

  return (
    <div>
      {!isFullScreenRoute && <Navbar isAuthenticated={isAuthenticated} updateAuthStatus={updateAuthStatus}/>}
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-mba/*" element={<AboutMBA />} />
          <Route path="/members" element={<Members />} />
          <Route path="/about-mba/organisation" element={<Organisation />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/events" element={<Events />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/member" element={<MemberForm />} /> */}
          <Route path="/academy" element={<Academy />} />
          <Route path="/events/detail" element={<EventDetails />} />
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
          <Route
            path='/register'
            element={
              <RedirectAuthenticated isAuthenticated={isAuthenticated}>
                <RegisterPage />
              </RedirectAuthenticated>
            }
            
          />
          <Route
            path='/validate'
            element={
              <RedirectAuthenticated isAuthenticated={isAuthenticated}>
                <ValidatePage />
              </RedirectAuthenticated>
            }
          />
          <Route
            path='/forgetPassword'
            element={
              <RedirectAuthenticated isAuthenticated={isAuthenticated}>
                <ForgetPasswordPage />
              </RedirectAuthenticated>
            }
          />
          <Route
            path='/login'
            element={
              <RedirectAuthenticated isAuthenticated={isAuthenticated}>
                <LoginPage updateAuthStatus={updateAuthStatus} />
              </RedirectAuthenticated>
            }
          />
          {/* <Route path='/validate' element={<ValidatePage />} />
          <Route path='/login' element={<LoginPage updateAuthStatus={updateAuthStatus} />} /> */}
         
           {/* <Route
            path="/admin"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <AdminPage />
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/admin"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} allowedRole="Admin">
                <AdminPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/address"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} allowedRole="Admin">
                <AddressPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/category"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} allowedRole="Admin">
                <AgeCategory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} allowedRole="*">
                <UserDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/events/register"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} allowedRole="*">
                <EventRegisterForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/events/register/players"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} allowedRole="*">
                <PlayerRegistrationForm />
              </ProtectedRoute>
            }
          />
          
          <Route
            path="/events/register/payment"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} allowedRole="*">
                <PaymentPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/team/details"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} allowedRole="*">
                <TeamDetail />
              </ProtectedRoute>
            }
          />    

          <Route
            path="/admin/teams"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} allowedRole="Admin">
                <AllTeams />
              </ProtectedRoute>
            }
          />  
          <Route
            path="/admin/validate"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} allowedRole="Admin">
                <PaymentValidation />
              </ProtectedRoute>
            }
          />
          
          <Route
            path="/controller"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} allowedRole="Admin">
                <Controller />
              </ProtectedRoute>
            }
          />
          <Route
            path="/display"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} allowedRole="Admin">
                <Display />
              </ProtectedRoute>
            }
          />
          <Route
            path="/createevent"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} allowedRole="Admin">
                <CreateEvents />
              </ProtectedRoute>
            }
          />
          <Route
            path="/member"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} allowedRole="Admin">
                <MemberForm />
              </ProtectedRoute>
            }
          />
        </Routes>
      </main>
      {!isFullScreenRoute && <Footer />}
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <TimerProvider>
        <AppContent />
        </TimerProvider>
    </Router>
  );
};

export default App;
