// TeamCard.js
import React from "react";
import "./TeamCard.css"; // Create a CSS file for styles (optional)

const TeamCard = ({ team, onViewDetails }) => {
    const imageNotFound = `${process.env.PUBLIC_URL}/imageNotFound.png`;
  return (
    <div className="team-card">
      <h3>{team.name}</h3>
      <p><strong>Category:</strong> {team.ageCategory ? team.ageCategory.category : "N/A"}</p>
      <p><strong>Gender:</strong> {team.gender}</p>
      <p><strong>Coach Name:</strong> {team.coachName}</p>
      <p><strong>Coach Mobile No:</strong> {team.coachMobileNo}</p>
      <p><strong>Manager Name:</strong> {team.managerName}</p>
      <p><strong>Manager Mobile No:</strong> {team.managerMobileNo}</p>
      {/* {team.coachPhoto && <img src={`data:image/jpeg;base64,${team.coachPhoto}`} alt="Coach" />}
      {team.managerPhoto && <img src={`data:image/jpeg;base64,${team.managerPhoto}`} alt="Manager" />} */}
       <img
        src={
          team.coachPhoto
            ? `data:image/jpeg;base64,${team.coachPhoto}`
            : imageNotFound
        }
        alt="Coach"
        className="team-photo"
      />

      {/* Manager Photo */}
      <img
        src={
          team.managerPhoto
            ? `data:image/jpeg;base64,${team.managerPhoto}`
            : imageNotFound
        }
        alt="Manager"
        className="team-photo"
      />
      <button
        className="view-team-details-btn"
        onClick={() => onViewDetails(team.id)}
      >
        View Team Details
      </button>
    </div>
  );
};

export default TeamCard;
