import React from 'react';
import './PlayerCard.css'; // Optional: Create this file to add styles

const PlayerCard = ({ player }) => {
  const imageNotFound = `${process.env.PUBLIC_URL}/imageNotFound.png`; // Placeholder for missing photos

  return (
    <div className='player-card-container'>
    <div className="player-card">
    {player.photo ? (
        <img src={`data:image/jpeg;base64,${player.photo}`} alt="Player" />
      ) : (
        <img src={imageNotFound} alt="Not available" />
      )}
      <h3>{player.name} {player.captain && <span>(Captain)</span>}</h3>
      <p><strong>Jersey No:</strong> {player.jerseyNo}</p>
      <p><strong>Date of Birth:</strong> {new Date(player.dob).toLocaleDateString()}</p>
      <p><strong>Gender:</strong> {player.gender}</p>
      <p><strong>Mobile No:</strong> {player.mobileNo}</p>
      <p><strong>Address:</strong></p>
      <div className='player-register-player-address'>
      <p>{player.address.street},</p>
      <p>{player.address.city},{player.address.state}</p>
      <p>{player.address.country},{player.address.postalCode}</p>
      </div>
      <p><strong>Emergency Contact:</strong></p>
      <p>{player.emergencyContactName} ({player.relationWithPlayer})</p>
      <p><strong>Emergency Mobile No:</strong> {player.emergencyContactMobileNo}</p>
      
    </div>
    </div>
  );
};

export default PlayerCard;
