import React, { useEffect, useState } from "react";
import useLocalStorage from "./useLocalStorage";
import { useTimer } from "./TimerContext";
import "./Controller.css";

const CounterComponent = () => {
  
  const [time, setTime] = useLocalStorage("time", 20);
  const [period, setPeriod] = useLocalStorage("period" , 1);
  const [homeScore, setHomeScore] = useLocalStorage("HomeScore", 0);
  const [awayScore, setAwayScore] = useLocalStorage("AwayScore", 0);
  const [matchNo, setMatchNo] = useLocalStorage("MatchNo", 0);
  const [matchName, setMatchName] = useLocalStorage("MatchName", "HOME VS AWAY");
  const [homeTeamName, setHomeTeamName] = useLocalStorage("homeTeamName", "HOME");
  const [awayTeamName, setAwayTeamName] = useLocalStorage("awayTeamName", "AWAY");
  const [homeTeamOut, setHomeTeamOut] = useLocalStorage("homeTeamOut", 0);
  const [awayTeamOut, setAwayTeamOut] = useLocalStorage("awayTeamOut", 0);
  const [homeTeamFoul, setHomeTeamFoul] = useLocalStorage("homeTeamFoul", 0);
  const [awayTeamFoul, setAwayTeamFoul] = useLocalStorage("awayTeamFoul", 0);
  const [homePlayerNo, setHomePlayerNo] = useLocalStorage("homePlayerNo", 0);
  const [awayPlayerNo, setAwayPlayerNo] = useLocalStorage("awayPlayerNo", 0);
  const [homePlayerFoul, setHomePlayerFoul] = useLocalStorage(
    "homePlayerFoul",
    0
  );
  const [awayPlayerFoul, setAwayPlayerFoul] = useLocalStorage(
    "awayPlayerFoul",
    0
  );

  const [activeArrow, setActiveArrow] = useLocalStorage("activeArrow",''); 

  const handleLeftArrow = () => {
    if(activeArrow !== 'left'){
      setActiveArrow('left'); 
    }else{
      setActiveArrow('');
    }
    
  };

  const handleRightArrow = () => {
    if(activeArrow !== 'right'){
      setActiveArrow('right'); 
    }else{
      setActiveArrow('');
    }
  };
  //   const [startTimer, setStartTimer] = useLocalStorage("startTimer", false);
  //   const [resetTimer, setResetTimer] = useLocalStorage("resetTimer", false);

  const increment = (setter, value) => setter(value + 1);
  const increment2 = (setter, value) => setter(value + 2);
  const increment3 = (setter, value) => setter(value + 3);
  const decrement = (setter, value) => {
    if (value > 0) {
      setter(value - 1);
    }
  };

  const remove = (setter, initialValue) => setter(initialValue);

  // useEffect(() => {
  //   let interval = null;
  //   if (isRunning) {
  //     interval = setInterval(() => {
  //       setTime(prevTime => {
  //         const newTime = prevTime - 1;
  //         localStorage.setItem('time', newTime); // Update localStorage every second
  //         return newTime;
  //       });
  //     }, 1000); // Update every second
  //   } else if (!isRunning && time !== 0) {
  //     clearInterval(interval);
  //   }
  //   return () => clearInterval(interval);
  // }, [isRunning, time]);
  

  const {
    // handleStartTimer,
    // handlePauseTimer,
    handleResetTimer,
    handleResetPeriod,
    handleIncreaseTime,
    handleDecreaseTime,
    handleTimer,
    isRunning,
  } = useTimer();

  const { handleStartTimer14, handleStartTimer24 } = useTimer();

  const resetAll = () => {
    setHomeScore(0);
    setAwayScore(0);
    setMatchNo(0);
    setMatchName("");
    setHomeTeamName("");
    setAwayTeamName("");
    setHomeTeamOut(0);
    setAwayTeamOut(0);
    setHomeTeamFoul(0);
    setAwayTeamFoul(0);
    setHomePlayerNo(0);
    setAwayPlayerNo(0);
    setHomePlayerFoul(0);
    setAwayPlayerFoul(0);
    handleResetTimer();
    handleResetPeriod();
    // console.log("Resetting...");
    // localStorage.removeItem("period");  // Clear existing value
    // setPeriod(1);  // Reset period to 1
    // console.log("Period reset to 1");
    setActiveArrow('');
  };

  return (
    <div>
      <div className="home">
        <div className="top-heading">
          <h1>Controller</h1>
        </div>
        <div className="controller-container">
          <div className="controller-heading">
            <div className="controller-matchNo">
              <h1>Match No: {matchNo}</h1>
              {/* <input
                type="number"
                value={matchNo}
                onChange={(e) => setMatchNo(Number(e.target.value))}
              /> */}
              <input
                type="number"
                value={matchNo}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (value >= 0) {
                    setMatchNo(value);
                  }
                }}
                min="0"
                className="controller-number-input"
              />
            </div>
            <div className="controller-match-heading">
              <h1>Match Name: {matchName}</h1>
              <input
                type="text"
                value={matchName}
                onChange={(e) => setMatchName(e.target.value)}
              />
            </div>
          </div>
          <div className="controller-body">
            <div className="left-div">
              <div className="team-name">
                <h3 className="controller-h3">Home Team Name:</h3>
                <input
                  type="text"
                  value={homeTeamName}
                  onChange={(e) => setHomeTeamName(e.target.value)}
                />
              </div>
              <div>
                <h3 className="controller-h3">Home Score: {homeScore}</h3>
                <div className="controller-buttons">
                  <button onClick={() => increment(setHomeScore, homeScore)}>
                    +1
                  </button>
                  <button onClick={() => increment2(setHomeScore, homeScore)}>
                    +2
                  </button>
                  <button onClick={() => increment3(setHomeScore, homeScore)}>
                    +3
                  </button>
                  <button onClick={() => decrement(setHomeScore, homeScore)}>
                    -1
                  </button>
                  <button onClick={() => remove(setHomeScore, 0)}>Reset</button>
                </div>
                <div>
                  <h3 className="controller-h3">
                    Home Team Out: {homeTeamOut}
                  </h3>
                  <div className="controller-buttons">
                    <button
                      onClick={() => increment(setHomeTeamOut, homeTeamOut)}
                    >
                      +1
                    </button>
                    <button
                      onClick={() => decrement(setHomeTeamOut, homeTeamOut)}
                    >
                      -1
                    </button>
                    <button onClick={() => remove(setHomeTeamOut, 0)}>
                      Reset
                    </button>
                  </div>
                </div>
                <div>
                  <h3 className="controller-h3">
                    Home Team Foul: {homeTeamFoul}
                  </h3>
                  <div className="controller-buttons">
                    <button
                      onClick={() => increment(setHomeTeamFoul, homeTeamFoul)}
                    >
                      +1
                    </button>
                    <button
                      onClick={() => decrement(setHomeTeamFoul, homeTeamFoul)}
                    >
                      -1
                    </button>
                    <button onClick={() => remove(setHomeTeamFoul, 0)}>
                      Reset
                    </button>
                  </div>
                </div>
                <div className="controller-player-content">
                  <div>
                    <h3 className="controller-h3">Player No: {homePlayerNo}</h3>
                    <div className="controller-buttons">
                      <input
                        type="number"
                        value={homePlayerNo}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          if (value >= 0) {
                            setHomePlayerNo(value);
                          }
                        }}
                        min="0"
                        className="controller-number-input"
                      />
                      <button onClick={() => remove(setHomePlayerNo, 0)}>
                        Reset
                      </button>
                    </div>
                  </div>
                  <div>
                    <h3 className="controller-h3">
                      Player Foul: {homePlayerFoul}
                    </h3>
                    <div className="controller-buttons">
                      <button
                        onClick={() =>
                          increment(setHomePlayerFoul, homePlayerFoul)
                        }
                      >
                        +1
                      </button>
                      <button
                        onClick={() =>
                          decrement(setHomePlayerFoul, homePlayerFoul)
                        }
                      >
                        -1
                      </button>
                      <button onClick={() => remove(setHomePlayerFoul, 0)}>
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="center-div">
              <div className="controller-timer">
                <div>
                  <h3 className="controller-h3">Increase Descrease Timer:</h3>
                  <div className="controller-buttons">
                    <button onClick={handleDecreaseTime}>-1 min</button>
                    <button onClick={handleIncreaseTime}>+1 min</button>
                  </div>
                  <div style={{marginTop:'20px'}} className="controller-buttons">
                    <button onClick={handleLeftArrow} style={{
          backgroundColor: activeArrow === 'left' ? 'yellow' : 'green', // Change color based on active arrow
          color: 'white',
          padding: '10px 20px',
          border: 'none',
          cursor: 'pointer',
        }}>Left Arrow</button>
                    <button onClick={handleRightArrow} style={{
          backgroundColor: activeArrow === 'right' ? 'yellow' : 'green', // Change color based on active arrow
          color: 'white',
          padding: '10px 20px',
          border: 'none',
          cursor: 'pointer',
        }}>Right Arrow</button>
                  </div>
                  {/* <div className="controller-timer">
                    <h1 className="timer">{Math.floor(time / 60)}:{time % 60 < 10 ? `0${time % 60}` : time % 60}</h1>
                    </div> */}
                  <h3 className="controller-h3">Timer:</h3>
                  <div className="controller-buttons">
                    <button onClick={handleTimer}>
                      {isRunning ? "Pause" : "Start"}
                    </button>
                    <button onClick={handleResetTimer}>Reset Timer</button>
                  </div>
                </div>
                {/* <div className="controller-buttons">
                    <button onClick={handleStartTimer14}>14 Seconds</button>
                    <button onClick={handleStartTimer24}>24 Seconds</button>
                </div> */}
                <h3 className="controller-h3">Restart Game:</h3>
                <button className="controller-reset-all" onClick={resetAll}>
                  Reset All
                </button>
                {/* <button onClick={() => remove(setPeriod, 1)}>Reset Period</button> */}
                {/* <input
                      type="number"
                      value={period}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        if (value >= 0) {
                          setPeriod(value);
                        }
                      }}
                      min="1"
                      className="controller-number-input"
                    /> */}
              </div>
            </div>
            <div className="right-div">
              <div className="team-name">
                <h3 className="controller-h3">Home Team Name:</h3>
                <input
                  type="text"
                  value={awayTeamName}
                  onChange={(e) => setAwayTeamName(e.target.value)}
                />
              </div>
              <div>
                <h3 className="controller-h3">Away Score: {awayScore}</h3>
                <div className="controller-buttons">
                  <button onClick={() => increment(setAwayScore, awayScore)}>
                    +1
                  </button>
                  <button onClick={() => increment2(setAwayScore, awayScore)}>
                    +2
                  </button>
                  <button onClick={() => increment3(setAwayScore, awayScore)}>
                    +3
                  </button>
                  <button onClick={() => decrement(setAwayScore, awayScore)}>
                    -1
                  </button>
                  <button onClick={() => remove(setAwayScore, 0)}>Reset</button>
                </div>
              </div>
              <div>
                <h3 className="controller-h3">Away Team Out: {awayTeamOut}</h3>
                <div className="controller-buttons">
                  <button
                    onClick={() => increment(setAwayTeamOut, awayTeamOut)}
                  >
                    +1
                  </button>
                  <button
                    onClick={() => decrement(setAwayTeamOut, awayTeamOut)}
                  >
                    -1
                  </button>
                  <button onClick={() => remove(setAwayTeamOut, 0)}>
                    Reset
                  </button>
                </div>
              </div>
              <div>
                <h3 className="controller-h3">
                  Away Team Foul: {awayTeamFoul}
                </h3>
                <div className="controller-buttons">
                  <button
                    onClick={() => increment(setAwayTeamFoul, awayTeamFoul)}
                  >
                    +1
                  </button>
                  <button
                    onClick={() => decrement(setAwayTeamFoul, awayTeamFoul)}
                  >
                    -1
                  </button>
                  <button onClick={() => remove(setAwayTeamFoul, 0)}>
                    Reset
                  </button>
                </div>
              </div>
              <div className="controller-player-content">
                <div>
                  <h3 className="controller-h3">Player No: {awayPlayerNo}</h3>
                  <div className="controller-buttons">
                    {/* <input
                      type="number"
                      value={awayPlayerNo}
                      onChange={(e) => setAwayPlayerNo(Number(e.target.value))}
                      className="controller-number-input"
                    /> */}
                    <input
                      type="number"
                      value={awayPlayerNo}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        if (value >= 0) {
                          setAwayPlayerNo(value);
                        }
                      }}
                      min="0"
                      className="controller-number-input"
                    />
                    <button onClick={() => remove(setAwayPlayerNo, 0)}>
                      Reset
                    </button>
                  </div>
                </div>
                <div>
                  <h3 className="controller-h3">
                    Player Foul: {awayPlayerFoul}
                  </h3>
                  <div className="controller-buttons">
                    <button
                      onClick={() =>
                        increment(setAwayPlayerFoul, awayPlayerFoul)
                      }
                    >
                      +1
                    </button>
                    <button
                      onClick={() =>
                        decrement(setAwayPlayerFoul, awayPlayerFoul)
                      }
                    >
                      -1
                    </button>
                    <button onClick={() => remove(setAwayPlayerFoul, 0)}>
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterComponent;
