import React from 'react';

const Vision = () => {
  return (
    <div className="mba-container">
      <div className="mba-content">
      <h2>Vision</h2>
      {/* <p>Whether you're a beginner, a school player seeking additional instruction, or an adult reconnecting with the game, MASTERS BASKETBALL ACADEMY offers programs tailored for all ages and abilities. Beyond the court, we provide diverse opportunities that cater to various interests.</p> */}
      <p>Whether you're a beginner eager to learn about basketball, a primary, middle, or high school player seeking additional instruction, an adult looking to reconnect with the game, or interested in joining the Masters Basketball Academy, we have a program for you! Our club extends beyond on-court action, offering programs for all ages, abilities, and interests</p>
    </div>
    </div>
  );
};

export default Vision;
