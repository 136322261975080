import React, { useState } from "react";
import "./StatusTimeline.css"; // Create a CSS file for styles
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import axiosInstance from "../../axiosConfig";

const StatusTimeline = ({ currentStatus }) => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState("");
  // Define the stages of the process
  const stages = [
    "Begin Registration Process",
    "Add Players to Your Team",
    "Complete Your Payment",
    "Payment Under Review",
    "Registration Completed"
  ];
  

  // Map the current status to its corresponding stage index
  const statusMap = {
    "Start Registration.": 0,
    "No players found in your team.": 1,
    "Payment not completed.": 2,
    "Payment is under review.": 3,
    "Payment failed.": 3,
    "Registration successful.": 4,
  };

  if (loading) {
    return <div className="loading-overlay">
    <div className="loading-text">Loading, please wait...</div>
  </div>;
  }

  async function getState(route)  {
    setLoading(true);
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
  
      const response = await axiosInstance.get("/team/resume/playerform", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      // setTeams(response.data);
  
  
      const teamDetails = response.data;
      setLoading(false);
      if (route === 'players') {
        navigate('/events/register/players', { state: { teamDetails } });
      } else if (route === 'payment') {
        navigate('/events/register/payment', { state: { teamDetails } });
      }
    } catch (error) {
      // setError("Error fetching teams.");
      setLoading(false);
    }
  }

  const currentStageIndex = statusMap[currentStatus] || 0; // Default to 0 if no valid status

  return (
    <div className="timeline">
      {stages.map((stage, index) => (
        <div key={index} className={`timeline-item ${index <= currentStageIndex ? "completed" : ""}`}>
          <div className="timeline-icon">{index + 1}</div>
          <div className="timeline-content">
            <h3>{stage}</h3>
            {index === currentStageIndex && <p>Current stage: {stage}</p>}
            {index === 1 && currentStageIndex === 1 && (
              <div className="resume-state">
                <button onClick={() => getState('players')}>Add Players</button>
              </div>
            )}
            {index === 2 && currentStageIndex === 2 && (
              <div className="resume-state">
                <button onClick={() => getState('payment')}>Make Payment</button>
              </div>
            )}
            {index === 3 && currentStageIndex === 3 && currentStatus === "Payment failed." && (
              <div className="resume-state-payment-failed">
                <button onClick={() => getState('payment')}>Retry Payment</button>
                <p className="error-message">Payment failed. Please try again.</p>
              </div>
            )}
             {index === 4 && currentStageIndex === 4 && (
              <div className="resume-state">
                <button onClick={() => getState('players')}>Add More Players</button>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StatusTimeline;
