import React from "react";
import "./AcademySubContainer.css";

const ProgramOverview = () => {
  const imgsrc = `${process.env.PUBLIC_URL}/academy/1.png`;
  const imgsrc1 = `${process.env.PUBLIC_URL}/academy/T5.png`;
  const imgsrc3 = `${process.env.PUBLIC_URL}/academy/T6.jpg`;
  return (
    <div className="academy-sub-container">
      <div className="academy-sub-content">
        <h3>Program Overview</h3>
        <p>
          The mission of MASTERS BASKETBALL ACADEMY is to inspire young athletes
          and to provide a unique development opportunity through exposure to
          established coaches with proven training techniques. The coaches will
          introduce and familiarize all student-athletes with MASTERS BASKETBALL
          ACADEMY elite training methodology and techniques.
        </p>
        <p>
          With input from experienced coaches, former players, and player
          development experts, MASTERS BASKETBALL ACADEMY developed a
          comprehensive basketball curriculum approved by the National
          Basketball Coaches Association that will be introduced in partnership
          with school coaches.
        </p>
        <p>
          The program provides on-court training, strength and conditioning
          exercises, and basketball education for young athletes between the
          ages of 6-18 who are interested in playing basketball.
        </p>
        <p>
          The program is executed by trained MASTERS BASKETBALL
          ACADEMY-Basketball coaches.
        </p>
        <p>
          The program is led by teams of local coaches with heads overseeing all
          training operations.
        </p>
      </div>
      <div className="academy-image">
        {/* <img  className='academy-img' src={imgsrc} alt="MBA Logo" />  */}
        <img className="gameday" src={imgsrc3} alt="MBA Logo" />
        {/* <img className="gameday" src={imgsrc2} alt="MBA Logo" /> */}
        <img className="gameday" src={imgsrc1} alt="MBA Logo" />
      </div>
    </div>
  );
};

export default ProgramOverview;
