import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; 
import axiosInstance from '../../axiosConfig';
import { toast } from "react-toastify";
import { Auth } from 'aws-amplify';
import './EventRegisterForm.css';

const EventRegisterForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    ageCategory: '',
    gender: '',
    street: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    coachName: '',
    coachMobileNo: '',
    coachPhoto: '', // Base64 string
    managerName: '',
    managerMobileNo: '',
    managerPhoto: '', // Base64 string
    eventId: ''
  });

  const navigate = useNavigate(); // for page navigation
  const location = useLocation();
  const { state } = location;
  const event = state?.event; // Access the event passed from EventDetails
  const [photoPreviews, setPhotoPreviews] = useState({
    coachPhoto: '',
    managerPhoto: ''
  });

  if (!event) return <div>No event details available for registration</div>;


  // Handling form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handling photo input (converting to base64)
  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData({ ...formData, [name]: reader.result.split(',')[1] }); // set Base64 string
      setPhotoPreviews({ ...photoPreviews, [name]: reader.result });
    };
    reader.readAsDataURL(file);
  };

  // Handling form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('handleSubmit triggered');  
    try {
      // console.log('inside try');
      formData.ageCategory = event.categoryId;
      formData.gender = event.categoryGender;
      formData.eventId = event.id;
      if(formData.postalCode.length !== 6){
        toast.warn("Postal code is not 6 digit");
        setFormData({...formData, postalCode : ''});
        return;
      }
      if (formData.coachMobileNo.length !== 10 || formData.managerMobileNo.length !== 10) {
        if (formData.coachMobileNo.length !== 10) {
          console.log("coach no " + formData.coachMobileNo);
          setFormData({ ...formData, coachMobileNo: '' });
        } else {
          console.log("manager no " + formData.managerMobileNo);
          setFormData({ ...formData, managerMobileNo: '' });
        }
        toast.warn("Mobile no. should be of 10 digits");
        return;
      }
      
      // console.log('form data updated');
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      // console.log("token is - " + token);
      // console.log("\n\n\nits printing");
      const response = await axiosInstance.post('/registration/register', formData, {
        headers: {
            'Content-Type': 'application/json',
             Authorization: `Bearer ${token}`
        },
    });
      // console.log('Team registered:', response.data);
      const teamDetails = response.data;
      toast.success('Team added successfully');

      navigate('/events/register/players', {state: {teamDetails}});
    } catch (error) {
      // console.error('Error registering team:', error);
      if(error.response && error.response.data){
        console.error('Error registering team: '+  error.response.data);
        toast.error('Error registering team: '+ error.response.data);
        }
        else{
          console.error('Error registering team:', error);
          toast.error('Error registering team:', error);
        }
    }
  };

  return (
    <div className='home'>
      <div className='top-heading'>
        <h1>Event Registration</h1>
      </div>
      <div className='team-registration-form-container ' style={{ marginTop: "20px" }}>
      <form onSubmit={handleSubmit}>
        {/* Team Info */}
        <div className='team-retistration-long-text-field'>
          <label className='team-registration-form-label'>Team Name: <span style={{ color: "red" }}>*</span></label>
          <input 
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className='team-register-display-flex'>
        <div className='team-retistration-short-text-field'>
          <label className='team-registration-form-label'>Age Category:</label>
          <input 
            type="text"
            name="ageCategory"
            value={`${event.categoryName}`}
            readOnly
          />
        </div>
        <div className='team-retistration-short-text-field'>
          <label className='team-registration-form-label'>Gender:</label>
          <input 
            type="text"
            name="ageCategory"
            value={event.categoryGender}
            readOnly
          />
          </div>
          {/* <select name="gender" value={formData.gender} onChange={handleChange} required>
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select> */}
        </div>

        {/* Address Info */}
        <h3>Address</h3>
        <div className='team-retistration-long-text-field'>
          <label className='team-registration-form-label'>Street: <span style={{ color: "red" }}>*</span></label>
          <textarea 
            type="text"
            name="street"
            value={formData.street}
            onChange={handleChange}
            required
          />
        </div>
        <div className='team-register-display-flex'>
        <div className='team-retistration-short-text-field'> 
          <label className='team-registration-form-label'>City: <span style={{ color: "red" }}>*</span></label>
          <input 
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </div>
        <div className='team-retistration-short-text-field'>
          <label className='team-registration-form-label'>State: <span style={{ color: "red" }}>*</span></label>
          <input 
            type="text"
            name="state"
            value={formData.state}
            onChange={handleChange}
            required
          />
        </div>
        </div>
        <div className='team-register-display-flex'>
        <div className='team-retistration-short-text-field'>
          <label className='team-registration-form-label'>Country: <span style={{ color: "red" }}>*</span></label>
          <input 
            type="text"
            name="country"
            value={formData.country}
            onChange={handleChange}
            required
          />
        </div>
        <div className='team-retistration-short-text-field'>
          <label className='team-registration-form-label'>Postal Code: <span style={{ color: "red" }}>*</span></label>
          <input 
            type="number"
            name="postalCode"
            value={formData.postalCode}
            maxLength="6" 
            pattern="^\d{6}$"
            onChange={handleChange}
            required
          />
        </div>
        </div>
        {/* Coach Info */}
        <h3>Coach Info</h3>
        <div className='team-retistration-long-text-field'>
          <label className='team-registration-form-label'>Coach Name: <span style={{ color: "red" }}>*</span></label>
          <input 
            type="text"
            name="coachName"
            value={formData.coachName}
            onChange={handleChange}
            required
          />
        </div>
        <div className='team-register-display-flex'>
        <div className='team-retistration-short-text-field'>
          <label className='team-registration-form-label'>Coach Mobile No: <span style={{ color: "red" }}>*</span></label>
          <input 
            type="number"
            name="coachMobileNo"
            value={formData.coachMobileNo}
            onChange={handleChange}
            required
          />
        </div>
        <div className='team-retistration-short-text-field'>
          <label className='team-registration-form-label'>Coach Photo:</label>
          <input 
            type="file"
            name="coachPhoto"
            accept="image/*"
            onChange={handleFileChange}
            required
          />
           
        </div>
        {photoPreviews.coachPhoto 
  ? <img className='team-registration-photo-preview' src={photoPreviews.coachPhoto} alt="Coach Preview" />
  : <p className='team-registration-photo-preview'></p>
}

        </div>
        {/* Manager Info */}
        <h3>Manager Info</h3>
        <div className='team-retistration-long-text-field'>
          <label className='team-registration-form-label'>Manager Name: <span style={{ color: "red" }}>*</span></label>
          <input 
            type="text"
            name="managerName"
            value={formData.managerName}
            onChange={handleChange}
            required
          />
        </div>
        <div className='team-register-display-flex'>
        <div className='team-retistration-short-text-field'>
          <label className='team-registration-form-label'>Manager Mobile No: <span style={{ color: "red" }}>*</span></label>
          <input 
            type="number"
            name="managerMobileNo"
            value={formData.managerMobileNo}
            onChange={handleChange}
            required
          />
        </div>
        <div className='team-retistration-short-text-field'>
          <label className='team-registration-form-label'>Manager Photo:</label>
          <input 
            type="file"
            name="managerPhoto"
            accept="image/*"
            onChange={handleFileChange}
            required
          />
        </div>
        {photoPreviews.managerPhoto 
  ? <img className='team-registration-photo-preview' src={photoPreviews.managerPhoto} alt="Coach Preview" />
  : <p className='team-registration-photo-preview'></p>
}
        </div>
        {/* Event Info */}
        {/* <div>
          <label>Event ID:</label>
          <input
            type="number"
            name="eventId"
            value={formData.eventId}
            onChange={handleChange}
            required
          />
        </div> */}

        {/* Submit Button */}
        <button type="submit" className='team-register-submit-button'>Save and Next</button>
      </form>
      </div>
    </div>
  );
};

export default EventRegisterForm;
