import React, { useState } from "react";
import "./Gallery.css";
import VideoPlayer from "../VideoPlayer";
import EventGallery from "./EventGallery";

const Gallery = () => {
  const videoName = "basketball.mp4";

  const [selectedEvent, setSelectedEvent] = useState(null);

  const photos = [
    {
      id: 1,
      event: "event1",
      src: `${process.env.PUBLIC_URL}/gallery/event1/1.jpeg`,
      description: "Australia Tour",
    },
    {
      id: 2,
      event: "event2",
      src: `${process.env.PUBLIC_URL}/gallery/event2/1.jpeg`,
      description: "First Victory in All India Master Games 2019",
    },
    {
      id: 3,
      event: "event3",
      src: `${process.env.PUBLIC_URL}/gallery/event3/1.jpeg`,
      description: "Karnataka Open",
    },
    {
      id: 4,
      event: "event4",
      src: `${process.env.PUBLIC_URL}/gallery/event4/1.jpeg`,
      description: "Australia Tour",
    },
    {
      id: 5,
      event: "event5",
      src: `${process.env.PUBLIC_URL}/gallery/event5/1.jpeg`,
      description: "4th National Games 50+ Bronze >edal",
    },
    {
      id: 6,
      event: "event6",
      src: `${process.env.PUBLIC_URL}/gallery/event6/1.jpeg`,
      description: "Masters Basketball Academy",
    },
    // { id: 7, event: 'event1', src: `${process.env.PUBLIC_URL}/events/event1/photo7.jpg` },
    // { id: 8, event: 'event2', src: `${process.env.PUBLIC_URL}/events/event2/photo8.jpg` },
    // { id: 9, event: 'event3', src: `${process.env.PUBLIC_URL}/events/event3/photo9.jpg` },
  ];

  const handlePhotoClick = (event) => {
    setSelectedEvent(event);
  };

  if (selectedEvent) {
    return (
      <EventGallery
        event={selectedEvent}
        onBack={() => setSelectedEvent(null)}
      />
    );
  }
  return (
    <div className="home">
      <div className="top-heading">
        <h1>Gallery</h1>
      </div>
      <div className="gallery-container">
        {photos.map((photo) => (
          <div
            key={photo.id}
            className="photo-item"
            onClick={() => handlePhotoClick(photo.event)}
          >
            <img src={photo.src} alt={`Photo ${photo.id}`} />
            <p className="photo-description">{photo.description}</p>
          </div>
        ))}

        {/* <div>
                <h1>Welcome to My App</h1>
                <VideoPlayer videoName={videoName} />
            </div> */}
      </div>
    </div>
  );
};

export default Gallery;
