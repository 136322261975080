import React from 'react'
import "./AcademySubContainer.css";

const Training = () => {
    const imgsrc =`${process.env.PUBLIC_URL}/academy/3.jpeg`;
    return (
        <div className="academy-sub-container">
        <div className="academy-sub-content">
            
          <h3>Training</h3>
          <p>
            Our skill development training has helped numerous athletes take their game to a higher level. Our training program is tailored to fit each athlete on an individual basis and our professional staff is committed to generating results on the court.
          </p>
          <p>
            We teach players how to be successful both on and off the court. We get to know our players so their training program can be tailored to help them improve in all areas of the game. Training sessions include work in the following areas:
          </p>
          <ul>
            <li>Shooting</li>
            <li>Ball Handling</li>
            <li>Offensive Moves (for Guards and Post Players)</li>
            <li>Footwork</li>
            <li>Rebounding</li>
            <li>Defense</li>
            <li>Speed and Strength Development</li>
          </ul>
          <p>
            Coaching ranges from one-day instruction to 12 - 52 weeks in duration and is focused on skill development for each athlete involved. We offer coaching at various times throughout the year.
          </p>
          </div>
        <div className='academy-image academy-img'>
            <img  src={imgsrc} alt="MBA Logo" /> 
    </div>
    
    </div>
  )
}

export default Training
