import React from 'react';
import { Link, Route, Routes, useResolvedPath, useMatch } from 'react-router-dom';

import './AboutMBA.css'; // Import the CSS file
import Mba from './Mba';
import Vision from './Vision';
import Mission from './Mission';
import Members from './member/Members';



const AboutMBA = () => {
  const resolvedPath = useResolvedPath('');
  const match = useMatch(`${resolvedPath.pathname}/*`);
  const basePath = match ? match.pathnameBase : '';

  return (
    <div className='home'>
      <div className='top-heading'>
        <h1>About MBA</h1>
      </div>
    <div className="about-mba-container">

      <div className="sub-tab-content">
        <Mba />
        <Vision />
        <Mission />
      </div>
    </div>
    </div>
  );
};

export default AboutMBA;
