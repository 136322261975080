import React from "react";
import Slider from "react-slick";
import "./Home.css";
import HomeTraining from "./HomeTraining";
import UpcommingEvent from "./UpcommingEvent";

const images = [
  // {
  //   src: `${process.env.PUBLIC_URL}/homePage/2.jpeg`,
  //   text: "Description for image 5",
  // },
  // {
  //   src: `${process.env.PUBLIC_URL}/homePage/avif.avif`,
  //   text: "Description for image 1",
  // },
  // {
  //   src: `${process.env.PUBLIC_URL}/homePage/webp.webp`,
  //   text: "Description for image 2",
  // },
  {
    src: `${process.env.PUBLIC_URL}/homePage/f1.png`,
    text: "Masters Basketball Academy was founded in 2018 with just 8 players.",
  },
  {
    src: `${process.env.PUBLIC_URL}/homePage/f2.png`,
    text: "Masters Basketball Academy was founded in 2018 with just 8 players.",
  },
  {
    src: `${process.env.PUBLIC_URL}/homePage/f3.png`,
    text: "The first MBA Karnataka team won Gold at the 3rd National Master Games in Vadodara, Gujarat.",
  },
  {
    src: `${process.env.PUBLIC_URL}/homePage/f4.png`,
    text: "The first MBA Karnataka team won Gold at the 3rd National Master Games in Vadodara, Gujarat.",
  },
  {
    src: `${process.env.PUBLIC_URL}/homePage/f6.png`,
    text: "The MBA team participated in the Pan Pacific Master Games on the Gold Coast, Australia.",
  },
  {
    src: `${process.env.PUBLIC_URL}/homePage/f8.png`,
    text: "The MBA Karnataka 60+ team won Bronze at the 4th National Master Games in Trivandrum, Kerala.",
  },
  {
    src: `${process.env.PUBLIC_URL}/homePage/f9.png`,
    text: "The MBA Karnataka 50+ team won Bronze at the 4th National Master Games in Trivandrum, Kerala.",
  },
  {
    src: `${process.env.PUBLIC_URL}/homePage/f10.jpeg`,
    text: "The MBA team participated in the Pan Pacific Master Games on the Gold Coast, Australia.",
  },
  {
    src: `${process.env.PUBLIC_URL}/homePage/f7.png`,
    text: "The first MBA Karnataka team won Gold at the 3rd National Master Games in Vadodara, Gujarat.",
  },
  {
    src: `${process.env.PUBLIC_URL}/homePage/f11.png`,
    text: "The 40+ MBA team represented Karnataka State in a tournament held in Goa.",
  },
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  fade: true,
  arrows: false,
};

const Home = () => {
  const videoName = "basketball.mp4";
  return (
    <div className="home-home">
      {/* <div className='heading-container'>
                <h1 className="heading">Masters Basketball Academy</h1>
            </div> */}
      <div className="content">
        <div className="body">
          <div className="slider-upcomingEvent-container">
            <div className="slider-container">
              <Slider {...settings}>
                {images.map((image, index) => (
                  <div key={index} className="slide">
                    <img src={image.src} alt={`Slide ${index}`} />
                    <p className="slide-text">{image.text}</p>
                  </div>
                ))}
              </Slider>
              <div className="slider-overlay">
                <h1 className="website-name">MASTERS BASKETBALL ACADEMY (R)</h1>
                <h3>
                  Play, Learn, Grow: Masters Basketball Academy for All Ages
                </h3>
              </div>
            </div>
            {/* <div className="upcoming-events">
              <UpcommingEvent />
            </div> */}
          </div>

          <div className="other-content">
            <div className="event-aboutMba">
              <div className="home-upcoming-events">
                <UpcommingEvent />
              </div>
              <div className="home-about-mba">
                <h1>About Us</h1>
                <h2>Welcome to The MASTERS BASKETBALL ACADEMY</h2>
                <p>
                  Welcome to The Masters basketball academy, where we are
                  committed to helping basketball plyers and students of age
                  group 6 to 18 years in different skill levels reach their full
                  potential. Our state-of-the-art facility, experienced coaches
                  and teachers provide a safe and supportive environment for
                  students to train, learn, and grow.
                </p>
                <p>
                  Our coaches are highly trained and have extensive experience
                  in Basketball. They are passionate about helping students to
                  succeed and dedicated in helping reach to your full potential.
                  They work closely with basketball players to develop
                  individualized training plans that focus on improving skills,
                  increasing strength endurance, and preventing injury.
                </p>
              </div>
            </div>
            <div className="home-coaching-content">
              <HomeTraining />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
