import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Auth } from 'aws-amplify';

const ProtectedRoute = ({ isAuthenticated, children, allowedRole }) => {
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    const fetchUserRole = async () => {
      try { 
        if (allowedRole !== '*') { // Fetch role only if a specific role is required
          const user = await Auth.currentAuthenticatedUser();
          const role = user.signInUserSession.idToken.payload['custom:role'];
          // console.log("User role from token:", role); 
          setUserRole(role);
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
        setUserRole(null);
      } finally {
        setLoading(false); // Stop loading after the role is fetched or if no role is required
      }
    };

    if (isAuthenticated) {
      fetchUserRole();
    } else {
      setLoading(false); // If not authenticated, no need to fetch role
    }
  }, [isAuthenticated, allowedRole]);

  if (loading) {
    return <div>Loading...</div>; // Show a loading state while waiting for the role
  }

  if (!isAuthenticated) {
    console.log("User is not authenticated, redirecting to login.");
    return <Navigate to="/login" />;
  }

  if (allowedRole !== '*' && userRole !== allowedRole) {
    // console.log(`User role (${userRole}) does not match allowed role (${allowedRole}), redirecting to unauthorized page.`);
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default ProtectedRoute;





// import React from 'react';
// import { Navigate } from 'react-router-dom';

// const ProtectedRoute = ({ isAuthenticated, children }) => {
//   if (!isAuthenticated) {
//     return <Navigate to="/login" />;
//   }
//   return children;
// };

// export default ProtectedRoute;

