import axios from "axios";
import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import axiosInstance from "../../axiosConfig";
import "./AdminPage.css";
import { useNavigate } from 'react-router-dom';

const AdminPage = () => {
  const [events, setEvents] = useState([
    //   { id: 1, name: 'Event 1', date: '2024-08-15', registered: 120 },
    //   { id: 2, name: 'Event 2', date: '2024-09-10', registered: 85 },
    //   { id: 3, name: 'Event 3', date: '2024-10-05', registered: 50 },
  ]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        setLoading(true);
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();

        const response = await axiosInstance.get("/admin/events/teamCounts", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (Array.isArray(response.data)) {
          setEvents(response.data);
        } else {
          console.error("Expected an array but got:", response.data);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching members:", error);
        // setError("Error fetching teams.");
        setLoading(false);
      }
    };

    fetchMembers();
  }, []);

  if (loading) {
    return (
      <div className="loading-overlay">
        <div className="loading-text">Loading, please wait...</div>
      </div>
    );
  }

  const handleCardClick = (eventId) => {
    console.log("admin page event id - " + eventId);
    navigate('/admin/teams', { state: { eventId } });
  };

  return (
    <div className="home">
    <div style={{ height: "10vh" }} className="top-heading">
    <h1>Admin Dashboard</h1>
    </div>
    <div className="admin-container">
      <h1>All Upcomming Event Details</h1>
      <div className="events-list">
        {/* <p>the response is</p>
              <p>{mess}</p> */}
        {Array.isArray(events) && events.length > 0 ? (
          events.map((event) => (
            <div
            key={event.eventId}
            className="event-card"
            onClick={() => handleCardClick(event.eventId)} // On card click, navigate
          >
              <h3>
                {event.eventName} 
              </h3>
              <p>({event.ageCategory})</p>
              <p>
                Event Start Date:{" "}
                {new Date(event.eventStartDate).toLocaleDateString()}
              </p>
              <p>
                Registration End Date:{" "}
                {new Date(event.registrationEndDate).toLocaleDateString()}
              </p>
              <p>Registered Teams: {event.teamCount }</p>
            </div>
          ))
        ) : (
          <p>No events found.</p>
        )}
      </div>
    </div>
    </div>
  );
};

export default AdminPage;
