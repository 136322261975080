import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./PaymentPage.css";
import { toast } from "react-toastify";
import axiosInstance from "../../axiosConfig";
import { Auth } from "aws-amplify";

const PaymentPage = () => {
    const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const teamDetails = state?.teamDetails;
  const qrCode = `${process.env.PUBLIC_URL}/paymentQr.png`;

  const [formData, setFormData] = useState({
    transactionId: '',
    teamEventId: '',
    paymentAmount: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // console.log("form data- " + formData.transactionId);
  };

  const handleSubmit = async  (e) => {
    e.preventDefault();  // Prevent page reload on form submit
    const teamEventId = String(formData.transactionId); 
    // console.log('Transaction ID length - ' + formData.transactionId);
    // console.log('Transaction ID length - ' + teamEventId.transactionId);
    if (formData.transactionId.length !== 12) {
      toast.error("Transaction ID must contain exactly 12 characters.");
      return;
    }
    // Merge the existing form data with the new values for teamEventId and paymentAmount
    const updatedFormData = {
      ...formData,
      teamEventId: teamDetails.eventTeamId,
      paymentAmount: teamDetails.paymentAmount
    };
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
        const response = await axiosInstance.post('/registration/payment', updatedFormData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

        // console.log('Payment Successful:', response.data);
       
        toast.success('Payment Successful:');
  
        navigate('/profile');
      } catch (error) {
        // console.error('Error registering team:', error);
        if(error.response && error.response.data){
          console.error('Error in making Payment: '+  error.response.data);
          toast.error('Error in making Payment: '+ error.response.data);
          }
          else{
            console.error('Error in making Payment:', error);
            toast.error('Error in making Payment:', error);
          }
      }
  };
  return (
    <div className="home">
      <div style={{ height: "10vh" }} className="top-heading">
        <h1>Payment Page</h1>
      </div>
      <div className="payment-page-body">
        <div className="payment-page-details">
          {/* <h3>Scan the code from your phone and make the payment</h3> */}
          <img className="payment-page-QR-Code" src={qrCode} alt="QR Code" />
        </div>

        <div className="payment-page-details">
          <div className="payment-page-heading">
            <div className="payment-page-total-amount">
              <h3><span style={{color: 'green'}}>Total Amount: </span> ₹ {teamDetails.paymentAmount}/-</h3> {/* Display total amount */}
            </div>
            <h2>Important Payment Details</h2>
          </div>
          <div className="payment-page-desc">
            <p>
              <strong>Merchant Name:</strong> MASTERS BASKETBALL ACADEMY BENGALURU
            </p>
            <p>
              <strong>UPI Handle (VPA):</strong> mbbab.05@cmsidfc
            </p>
          </div>

          <div className="payment-page-content">
            <p>
              <strong>Instructions:</strong> Please verify the{" "}
              <strong>merchant name</strong> and{" "}
              <strong>UPI handle (VPA)</strong> in your UPI app after scanning
              the QR code. Do not proceed with the payment if they are different
              from the details provided here.
            </p>
            <p>
              After completing the payment, please enter the{" "}
              <strong>transaction ID</strong> in the form below to confirm your
              payment.
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <h2>Enter Transaction ID:</h2>
            <input
              type="text"
             name="transactionId"
              placeholder="Transaction Id"
                value={formData.transactionId}
                onChange={handleChange}
              required
            />
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
