// import { useState, useEffect } from 'react';

// const useLocalStorageSync = (key, initialValue = 0) => {
//   const [value, setValue] = useState(() => {
//     const storedValue = localStorage.getItem(key);
//     return storedValue ? parseInt(storedValue, 10) : initialValue;
//   });

//   useEffect(() => {
//     const handleStorageChange = () => {
//       const storedValue = localStorage.getItem(key);
//       setValue(storedValue ? parseInt(storedValue, 10) : initialValue);
//     };

//     window.addEventListener("storage", handleStorageChange);

//     return () => {
//       window.removeEventListener("storage", handleStorageChange);
//     };
//   }, [key, initialValue]);

//   return value;
// };

// export default useLocalStorageSync;
import { useState, useEffect } from 'react';

const useLocalStorageSync = (key, initialValue) => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : initialValue;
  });

  useEffect(() => {
    const handleStorageChange = () => {
      const storedValue = localStorage.getItem(key);
      setValue(storedValue ? JSON.parse(storedValue) : initialValue);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key, initialValue]);

  return value;
};

export default useLocalStorageSync;
