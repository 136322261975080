import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './UpcommingEvent.css';
import { Link } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';

const UpcommingEvent = () => {
  const imgsrc =`${process.env.PUBLIC_URL}/gameday.png`;
  const [events, setEvents] = useState([]);
  const [nextEvent, setNextEvent] = useState(null);
  const [timeLeft, setTimeLeft] = useState({
    hours: '00',
    minutes: '00',
    seconds: '00'
  });
  const [daysLeft, setDaysLeft] = useState('0');

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axiosInstance.get('/events');
        setEvents(response.data);
        // console.log("response - ", response.data);
        
        if (response.data.length > 0) {
          const sortedEvents = response.data.sort(
            (a, b) => new Date(a.eventStartDate) - new Date(b.eventStartDate)
          );
          // console.log("sortedEvents - ", sortedEvents);
          setNextEvent(sortedEvents[0]);
        } else {
          console.log("No events found.");
        }
        
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };
  
    fetchEvents();
  }, []);
  

  // useEffect(() => {
  //   if (nextEvent) {
  //     const calculateDaysLeft = (eventDate) => {
  //       const eventTime = new Date(eventDate).getTime();
  //       const currentTime = new Date().getTime();
  //       const timeDiff = eventTime - currentTime;
  //       const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  //       return daysLeft;
  //     };

  //     const eventTime = new Date(nextEvent.date + ' ' + nextEvent.time).getTime();
  //     const currentTime = new Date().getTime();
  //     const timeDiff = eventTime - currentTime;
  //     const days = calculateDaysLeft(nextEvent.date);
  //     setDaysLeft(days);

  //     const interval = setInterval(() => {
  //       const currentTime = new Date().getTime();
  //       const timeDiff = eventTime - currentTime;

  //       if (timeDiff <= 0) {
  //         clearInterval(interval);
  //         setTimeLeft({ hours: '00', minutes: '00', seconds: '00' });
  //       } else {
  //         const hours = String(Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
  //         const minutes = String(Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
  //         const seconds = String(Math.floor((timeDiff % (1000 * 60)) / 1000)).padStart(2, '0');
  //         setTimeLeft({ hours, minutes, seconds });
  //       }
  //     }, 1000);

  //     return () => clearInterval(interval);
  //   }
  // }, [nextEvent]);

  useEffect(() => {
    if (nextEvent) {
      const calculateDaysLeft = (eventDate) => {
        const eventDateTime = new Date(eventDate + ' 00:00:00').getTime();
        const currentTime = new Date().getTime();
        const timeDiff = eventDateTime - currentTime;
        const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        return daysLeft;
      };
  
      const eventDateTime = new Date(nextEvent.eventStartDate + ' 00:00:00').getTime();
      const currentTime = new Date().getTime();
      const days = calculateDaysLeft(nextEvent.eventStartDate);
      setDaysLeft(days);
  
      const interval = setInterval(() => {
        const currentTime = new Date().getTime();
        const timeDiff = eventDateTime - currentTime;
  
        if (timeDiff <= 0) {
          clearInterval(interval);
          setTimeLeft({ hours: '00', minutes: '00', seconds: '00' });
        } else {
          const hours = String(Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
          const minutes = String(Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
          const seconds = String(Math.floor((timeDiff % (1000 * 60)) / 1000)).padStart(2, '0');
          setTimeLeft({ hours, minutes, seconds });
        }
      }, 1000);
  
      return () => clearInterval(interval);
    }
  }, [nextEvent]);
  

  return (
    <div className='upcommingEvent-body'>
      <h1>Next Event</h1>
      <div className='nextEvent'>
      <div className='nextEvent-Name custom-font'>
          {nextEvent ? nextEvent.name : 'No upcoming events'}
        </div>
        <div className='gameday-container'>
        <img className='gameday' src={imgsrc} alt="MBA Logo" /> 
        </div>
        <div className='nextEvent-Time'>
          <div>
            {nextEvent ? `${daysLeft}` : '00'}
            <span>{`Day${daysLeft !== 1 ? 's' : ''}`}</span>
          </div>
          <div>
            {timeLeft.hours}
            <span> Hours </span>
          </div>
          <div>
            {timeLeft.minutes}
            <span> Minutes </span>
          </div>
          <div className='next-event-seconds'>
            {timeLeft.seconds}
            <span className='next-event-seconds'> Seconds </span>
          </div>
        </div>
        
        <div className='nextEvent-Register'>
          <Link to="/events" className="custom-link">Register Now</Link>
        </div>
      </div>
      {/* <div className='later-Event'>
        {events.slice(1, 3).map((event, index) => (
          <div key={index} className='event'>
            <div className='event-text'>{event.name}</div>
            <div className='event-date'>
              {`${Math.ceil((new Date(event.date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))} Days Left`}
            </div>
            <div className='event-Time'>{event.time}</div>
            <div className='event-Place'>{event.place}</div>
            <div className='event-Category'>{event.category}</div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default UpcommingEvent;
