import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosConfig";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import "./MemberForm.css";

const MemberForm = () => {
  const [member, setMember] = useState({
    name: "",
    age: "",
    achievements: "",
    profession: "",
    occupation: "",
  });
  const [photo, setPhoto] = useState(null);
  const [members, setMembers] = useState([]);
  const [memberToDelete, setMemberToDelete] = useState(null);

  useEffect(() => {
    fetchMembers();
  }, []);

  // Fetch members from the backend
  const fetchMembers = async () => {
    try {
      const response = await axiosInstance.get("/members/all");
      setMembers(response.data);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  // Handle form inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMember({ ...member, [name]: value });
  };

  // Handle photo input
  const handleFileChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  // Submit new member data
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("member", new Blob([JSON.stringify(member)], { type: "application/json" }));
    formData.append("photo", photo);

    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      await axiosInstance.post("/members/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      fetchMembers();
      setMember({ name: "", age: "", achievements: "", profession: "", occupation: "" });
      setPhoto(null);
      toast.success("Member added successfully!");
    } catch (error) {
      toast.error("Error uploading member details!");
      console.error("Error uploading member details:", error);
    }
  };

  // Confirm delete member
  const confirmDelete = async () => {
    if (memberToDelete) {
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        await axiosInstance.delete(`/members/${memberToDelete.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        fetchMembers();
        setMemberToDelete(null);
        toast.success("Member deleted successfully.");
      } catch (error) {
        toast.error("Error deleting member.");
        console.error("Error deleting member:", error);
      }
    }
  };

  // Trigger delete confirmation
  const handleDeleteClick = (member) => {
    setMemberToDelete(member);
  };

  return (
    <div className="add-member-details">
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Name <span className="required">*</span>
            <input
              type="text"
              className="member-form-inputbox"
              name="name"
              value={member.name}
              onChange={handleInputChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Age <span className="required">*</span>
            <input
              type="number"
              className="member-form-inputbox"
              name="age"
              value={member.age}
              onChange={handleInputChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Achievements <span className="required">*</span>
            <input
              type="text"
              className="member-form-inputbox"
              name="achievements"
              value={member.achievements}
              onChange={handleInputChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Profession <span className="required">*</span>
            <input
              type="text"
              className="member-form-inputbox"
              name="profession"
              value={member.profession}
              onChange={handleInputChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Occupation <span className="required">*</span>
            <select
              className="member-form-inputbox"
              name="occupation"
              value={member.occupation}
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>
                Select Occupation
              </option>
              <option value="President">President</option>
              <option value="Vice President">Vice President</option>
              <option value="General Secretary">General Secretary</option>
              <option value="Organization Secretary">Organization Secretary</option>
              <option value="Joint Secretary">Joint Secretary</option>
              <option value="Advisory Committee">Advisory Committee</option>
              <option value="Founding Members">Founding Members</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            Photo <span className="required">*</span>
            <input
              type="file"
              className="member-form-photo"
              onChange={handleFileChange}
              required
            />
          </label>
        </div>
        <button className="member-form-button" type="submit">
          Submit
        </button>
      </form>

      <div className="member-list">
        <h2>All Members</h2>
        {members.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Photo</th>
                <th>Name</th>
                <th>Age</th>
                <th>Achievement</th>
                <th>Profession</th>
                <th>Occupation</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {members.map((member) => (
                <tr key={member.id}>
                  <td>
                    <img
                      src={`data:image/jpeg;base64,${member.photo}`}
                      alt={`${member.name}'s photo`}
                      className="photo-preview"
                    />
                  </td>
                  <td>{member.name}</td>
                  <td>{member.age}</td>
                  <td>{member.achievements}</td>
                  <td>{member.profession}</td>
                  <td>{member.occupation}</td>
                  <td>
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteClick(member)}
                    >
                      🗑️ Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No members found.</p>
        )}
      </div>

      {memberToDelete && (
        <div className="confirmation-popup">
          <div className="popup-content">
            <p>Are you sure you want to delete this member?</p>
            <button onClick={confirmDelete}>Yes</button>
            <button onClick={() => setMemberToDelete(null)}>No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MemberForm;






// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import "./MemberForm.css";
// import axiosInstance from "../../axiosConfig";
// import { Auth } from "aws-amplify";
// import { toast } from "react-toastify";

// const MemberForm = () => {
//   const [member, setMember] = useState({
//     name: "",
//     age: "",
//     achievements: "",
//     profession: "",
//     occupation: "",
//   });
//   const [photo, setPhoto] = useState(null);

//   const [members, setMemberss] = useState([]);
//   const [membersToDelete, setMembersToDelete] = useState(null);

//   useEffect(() => {
//     fetchMember();
//   }, []);

//   const fetchMember = async () => {
//     try {
//       const response = await axiosInstance.get("/members/all");
//       setMemberss(response.data);
//     } catch (error) {
//       console.error("Error fetching addresses:", error);
//     }
//   };

//   const handleDeleteClick = (member) => {
//     setMembersToDelete(member);
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setMember({ ...member, [name]: value });
//   };

//   const handleFileChange = (e) => {
//     setPhoto(e.target.files[0]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append(
//       "member",
//       new Blob([JSON.stringify(member)], { type: "application/json" })
//     );
//     formData.append("photo", photo);

//     try {
//       const session = await Auth.currentSession();
//       const token = session.getIdToken().getJwtToken();
//       const response = await axiosInstance.post("/members/add", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       fetchMember();
//       setMember({
//         name: "",
//         age: "",
//         achievements: "",
//         profession: "",
//         occupation: "",
//       });
//       toast.success("Member added successfully!");
//       console.log(response.data);
//     } catch (error) {
//       if (error.response && error.response.data) {
//         toast.error(`Error: ${error.response.data}`);
//       } else {
//         toast.error("Error uploading member details!");
//       }
//       console.error("Error uploading member details:", error);
//     }
//   };

//   const confirmDelete = async () => {
//     if (membersToDelete) {
//       try {
//         const session = await Auth.currentSession();
//         const token = session.getIdToken().getJwtToken();
//         await axiosInstance.delete(`/addresses/${membersToDelete.id}`, {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }); // Replace with your backend API endpoint
//         fetchMember(); // Refresh the address list after deleting
//         setMembersToDelete(null);
//         toast.success("Address deleted successfully.");
//       } catch (error) {
//         if (error.response && error.response.data) {
//           console.error("Error deleting address: " + error.response.data);
//           toast.error("Error deleting address: " + error.response.data);
//         } else {
//           console.error("Error deleting address.", error);
//           toast.error("Error deleting address.", error);
//         }
//       }
//     }
//   };

//   // const handleUpdate = async (e) => {
//   //     e.preventDefault();
//   //     const formData = new FormData();
//   //     formData.append('member', new Blob([JSON.stringify(member)], { type: 'application/json' }));
//   //     formData.append('photo', photo);

//   //     try {
//   //         const session = await Auth.currentSession();
//   //         const token = session.getIdToken().getJwtToken();
//   //         const response = await axiosInstance.put('/members/update', formData, {
//   //             headers: {
//   //                 'Content-Type': 'multipart/form-data',
//   //                 Authorization: `Bearer ${token}`
//   //             },
//   //         });

//   //         alert('Member updated successfully!');
//   //         console.log(response.data);
//   //     } catch (error) {
//   //         if (error.response && error.response.data) {
//   //             alert(`Error: ${error.response.data}`);
//   //         } else {
//   //             alert('Error updating member details!');
//   //         }
//   //         console.error('Error updating member details:', error);
//   //     }
//   // };

//   return (
//     <div className="add-member-details">
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label>
//             Name:<span className="required">*</span>
//             <input
//               type="text"
//               className="member-form-inputbox"
//               name="name"
//               value={member.name}
//               onChange={handleInputChange}
//               required
//             />
//           </label>
//         </div>
//         <div>
//           <label>
//             Age:<span className="required">*</span>
//             <input
//               type="number"
//               className="member-form-inputbox"
//               name="age"
//               value={member.age}
//               onChange={handleInputChange}
//               required
//             />
//           </label>
//         </div>
//         <div>
//           <label>
//             Achievements:<span className="required">*</span>
//             <input
//               type="text"
//               className="member-form-inputbox"
//               name="achievements"
//               value={member.achievements}
//               onChange={handleInputChange}
//               required
//             />
//           </label>
//         </div>
//         <div>
//           <label>
//             Profession:<span className="required">*</span>
//             <input
//               type="text"
//               className="member-form-inputbox"
//               name="profession"
//               value={member.profession}
//               onChange={handleInputChange}
//               required
//             />
//           </label>
//         </div>
//         <div>
//           <label>
//             Occupation:<span className="required">*</span>
//             <select
//               className="member-form-inputbox"
//               name="occupation"
//               value={member.occupation}
//               onChange={handleInputChange}
//               required
//             >
//               <option value="" disabled>
//                 Select Occupation
//               </option>
//               <option value="President">President</option>
//               <option value="Vice President">Vice President</option>
//               <option value="General Secretary">General Secretary</option>
//               <option value="Organization Secretary">
//                 Organization Secretary
//               </option>
//               <option value="Joint Secretary">Joint Secretary</option>
//               <option value="Advisory Committee">Advisory Committee</option>
//               <option value="Founding Members">Founding Members</option>
//             </select>
//           </label>
//         </div>

//         <div>
//           <label>
//             Photo:<span className="required">*</span>
//             <input
//               type="file"
//               className="member-form-photo"
//               onChange={handleFileChange}
//               required
//             />
//           </label>
//         </div>
//         <button className="member-form-button" type="submit">
//           Submit
//         </button>
//         {/* <button className='member-form-button'  type="button" onClick={handleUpdate}>Update</button> */}
//       </form>

//       <div className="member-list">
//         <h2>All Addresses</h2>
//         {members.length > 0 ? (
//           <table>
//             <thead>
//               <tr>
//                 <th>Photo</th>
//                 <th>Name</th>
//                 <th>Age</th>
//                 <th>Achievement</th>
//                 <th>Profession</th>
//                 <th>Occupation</th>
//                 <th>Delete</th>
//               </tr>
//             </thead>
//             <tbody>
//               {members.map((member) => (
//                 <tr key={member.id}>
//                     <td><img
//                         src={`data:image/jpeg;base64,${member.photo}`}
//                         alt={`${member.name}'s photo`}
//                         className="photo-preview"
//                   /></td>
//                   <td>{member.name}</td>
//                   <td>{member.age}</td>
//                   <td>{member.achievements}</td>
//                   <td>{member.profession}</td>
//                   <td>{member.occupation}</td>
//                   <td>
//                     <button
//                       className="delete-button"
//                       onClick={() => handleDeleteClick(member)}
//                     >
//                       🗑️ Delete
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         ) : (
//           <p>No addresses found.</p>
//         )}
//       </div>

//       {/* Confirmation Popup */}
//       {membersToDelete && (
//         <div className="confirmation-popup">
//           <div className="popup-content">
//             <p>Are you sure you want to delete this address?</p>
//             <button onClick={confirmDelete}>Yes</button>
//             <button onClick={() => setMembersToDelete(null)}>No</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default MemberForm;
