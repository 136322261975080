import React from "react";
import "./Mba.css"; // Import the CSS file

const Mba = () => {
  return (
    <div className="mba-container">
      <div className="mba-content">
        <h2>MASTERS BASKETBALL ACADEMY</h2>
        {/* <p>Welcome to the MASTERS BASKETBALL ACADEMY Club, where we prioritize basketball fundamentals, individual improvement, and team building. Our aim is to enrich our communities through basketball by creating memorable experiences centered on teamwork and sportsmanship.</p>
        <p>Join us as we nurture a passion for basketball, helping players of all ages and skill levels develop their potential both on and off the court. At MASTERS BASKETBALL ACADEMY, we're dedicated to fostering a supportive environment where players can thrive and grow together.</p> */}
        <p>
          The "Masters Basketball Academy" is dedicated to fostering a lifelong
          spirit of healthy competition and lasting friendships among mature
          adult sports enthusiasts, affectionately known as 'Masters,'
          regardless of age or gender. What unites us is our shared passion for
          the enchanting sport of basketball. The Academy welcomes Masters to
          join other like-minded basketball enthusiasts to explore new
          opportunities to play the game together.
        </p>
        <p>
          As a Sports Academy committed to the philosophy that "Age is just a
          number," our primary objective is to bring people together, hold
          regular practice sessions, and organize events and tournaments that
          provide an excellent platform for members to showcase their basketball
          skills.
        </p>
        <h2>Our Aims include:</h2>
        <p>
          Partnering with schools to develop basketball programs for youths both
          boyes ang girles players aged 5-18. Nurturing basketball talent while
          promoting positive NBA values of integrity, teamwork, respect, and
          innovation. Affiliation with state, national, and international
          Masters Sports Associations to participate in their events. Promoting
          basketball among individuals who are ready to defy their age and
          go the extra mile.
        </p>
      </div>
    </div>
  );
};

export default Mba;
