import React from 'react';
import './CustomAlert.css';

const CustomAlert = ({ show, message, onClose, onConfirm }) => {
  if (!show) return null;

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm(); // Call the provided callback function if it exists
    }
    onClose(); // Always close the alert after confirming
  };

  return (
    <div className="custom-alert-overlay">
      <div className="custom-alert-box">
        <p>{message}</p>
        <button onClick={handleConfirm}>OK</button>
      </div>
    </div>
  );
};

export default CustomAlert;
