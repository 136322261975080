import React from "react";
import "./Academy.css";
import ProgramOverview from "./ProgramOverview";
import BasketballCurriculum from "./BasketballCurriculum";
import Training from "./Training";
import CoachingProgram from "./CoachingProgram";

const Academy = () => {
  return (
    <div className="home">
      <div className="top-heading">
        <h1>Academy</h1>
      </div>
      <div className="academy-container">
        <div className="academy-content">
          <h2>A Commitment to Excellence</h2>
          <p>The MASTERS BASKETBALL ACADEMY  Basketball School curriculum is designed to help players, parents, coaches and organizations better understand the process of improvement and development. </p>
          <p> The program focuses on court training, strength and conditioning, and basketball education under the direction of local coaches who work directly with the MASTERS BASKETBALL ACADEMY ’s</p>
          
          <ProgramOverview />

          <BasketballCurriculum />

          <Training />

          <CoachingProgram />
          
          <h3>Skills Competitions</h3>
          <p>
            Compete against other ballers in your city to find out who has the best handles, sweetest stroke, and quickest feet in a Skills Competition. These events consist of different offensive skills challenges to pit your game against other contestants.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Academy;
