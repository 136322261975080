import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AgeCategory.css";
import axiosInstance from "../../../axiosConfig";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";

const AgeCategory = () => {
  const [form, setForm] = useState({
    category: "",
    minAge: "",
    maxAge: "",
    gender: "",
  });

  const [ageCategories, setAgeCategories] = useState([]);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  // Fetch existing age categories from the backend when the component mounts
  useEffect(() => {
    fetchAgeCategories();
  }, []);

  const fetchAgeCategories = async () => {
    try {
      const response = await axiosInstance.get("/categories"); // Replace with your backend API endpoint
      setAgeCategories(response.data);
    } catch (error) {
      console.error("Error fetching age categories:", error);
    }
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (parseInt(form.maxAge) <= parseInt(form.minAge)) {
      toast.error("Maximum Age must be greater than Minimum Age");
      return;
    }
    if(parseInt(form.maxAge) <0  || parseInt(form.minAge) < 0){
      toast.error("Age should be greater than 0");
      return;
    }
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      await axiosInstance.post("/categories", form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }); // Replace with your backend API endpoint
      fetchAgeCategories(); // Refresh the age category list after adding a new one
      setForm({
        category: "",
        minAge: "",
        maxAge: "",
        gender: "",
      });
      toast.success("Age Categories added successfully.");
    } catch (error) {
      if (error.response && error.response.data) {
      console.error("Error adding age category: "+ error.response.data);
      toast.error("Error adding age category: "+ error.response.data);
      }
      else{
        console.error("Error adding age category: "+ error);
      toast.error("Error adding age category: "+ error);
      }
    }
  };

  const handleDeleteClick = (category) => {
    setCategoryToDelete(category);
  };

  const confirmDelete = async () => {
    if (categoryToDelete) {
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        await axiosInstance.delete(`/categories/${categoryToDelete.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }); // Replace with your backend API endpoint
        fetchAgeCategories(); // Refresh the age category list after deleting
        setCategoryToDelete(null);
        toast.success("Age Categories deleted successfully.");
      } catch (error) {
        if (error.response && error.response.data) {
          console.error("Error deleting age category: " + error);
          toast.error("Error deleting age category: " + error.response.data);
        } else {
          console.error("Error deleting age category: " + error);
          toast.error("Error deleting age category: " + error);
        }
      }
    }
  };

  return (
    <div className="age-category-page">
      {/* Part 1: Add New Age Category */}
      <div className="add-age-category">
        <h2>Add New Age Category</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Category <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="category"
              value={form.category}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>
              Minimum Age <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="number"
              name="minAge"
              value={form.minAge}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>
              Maximum Age <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="number"
              name="maxAge"
              value={form.maxAge}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>
              Gender <span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="gender"
              value={form.gender}
              onChange={handleChange}
              required
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Others">Others</option>
              <option value="All">All</option>
            </select>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>

      {/* Part 2: Display All Age Categories */}
      <div className="age-category-list">
        <h2>All Age Categories</h2>
        {ageCategories.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Category</th>
                <th>Minimum Age</th>
                <th>Maximum Age</th>
                <th>Gender</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {ageCategories.map((category) => (
                <tr key={category.id}>
                  <td>{category.category}</td>
                  <td>{category.minAge}</td>
                  <td>{category.maxAge}</td>
                  <td>{category.gender}</td>
                  <td>
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteClick(category)}
                    >
                      🗑️ Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No age categories found.</p>
        )}
      </div>

      {/* Confirmation Popup */}
      {categoryToDelete && (
        <div className="confirmation-popup">
          <div className="popup-content">
            <p>Are you sure you want to delete this age category?</p>
            <button onClick={confirmDelete}>Yes</button>
            <button onClick={() => setCategoryToDelete(null)}>No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AgeCategory;
