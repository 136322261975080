import React, { useState, useEffect } from "react";
import axiosInstance from "../../axiosConfig";
import "./CreateEvents.css";
import { toast } from 'react-toastify';
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";

const CreateEvents = () => {
  const [events, setEvents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [formData, setFormData] = useState({
    category: "",
    addressId: "",
    name: "",
    registrationStartDate: "",
    registrationEndDate: "",
    eventStartDate: "",
    eventEndDate: "",
    registrationFees: "",
    eventDescription: "",
  });

  const fetchEvents = async () => {
    try {
      const response = await axiosInstance.get("/events");
      setEvents(response.data);
    } catch (error) {
      toast.error("Failed to fetch events.");
    }
  };

  const fetchAddresses = async () => {
    try {
      const response = await axiosInstance.get("/addresses");
      setAddresses(response.data);
    } catch (error) {
      toast.error("Failed to fetch addresses.");
    }
  };

  useEffect(() => {
    fetchEvents();
    fetchCategories();
    fetchAddresses();
  }, []);

  const handleChange = (e) => {
    // console.log('name- ' + e.target.name);
    // console.log('value- ' + e.target.value);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      await axiosInstance.post("/events", formData, {
        headers: {
            'Content-Type': 'application/json',
             Authorization: `Bearer ${token}`
        },
    });
      toast.success("Event created successfully.");
      fetchEvents();
      setFormData({
        category: "",
        addressId: "",
        name: "",
        registrationStartDate: "",
        registrationEndDate: "",
        eventStartDate: "",
        eventEndDate: "",
        registrationFees: "",
        eventDescription: "",
      });
    } catch (error) {
      if (error.response && error.response.data) {
        // Backend error message
        toast.error("Failed to create event: " + error.response.data);
    } else {
        // Generic error message
        toast.error("Failed to create event.");
    }
    }
  };

  const confirmDelete = async (event) => {
    if (eventToDelete) {
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        await axiosInstance.delete(`/events/${event.id}`, {
          headers: {
              'Content-Type': 'application/json',
               Authorization: `Bearer ${token}`
          },
      });
        toast.success("Event deleted successfully.");
        fetchEvents();
        setEventToDelete(null);
      } catch (error) {
        toast.error("Failed to delete event.");
      }
    }
  };
  const handleDeleteClick = (event) => {
    setEventToDelete(event);
  };

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get("/categories");
      setCategories(response.data);
    } catch (error) {
      toast.error("Failed to fetch categories.");
    }
  };

  return (
    <div className="create-events-page">
      <div className="add-event">
        <h2 className="create-event-heading">Create New Event</h2>
        {/* <form onSubmit={handleSubmit}>
        <div> */}
  <form onSubmit={handleSubmit}>
    <div>
      <label>
        Category <span style={{ color: 'red' }}>*</span>
      </label>
      <select
        name="category"
        value={formData.category}
        onChange={handleChange}
        required
      >
        <option value="" disabled>Select a category</option>
        {categories.map((category) => (
          <option key={category.id} value={category.id}>
          {category.category} ({category.minAge}-{category.maxAge} years, {category.gender})
          </option>
        ))}
      </select>
      <div style={{ marginTop: '10px' }}>
    <Link to="/category" style={{ color: '#007bff', textDecoration: 'none' }}>
      Click here to add a new Age Category
    </Link>
  </div>
    </div>
    <div>
      <label>
        Address <span style={{ color: 'red' }}>*</span>
      </label>
      <select
        name="addressId"
        value={formData.addressId}
        onChange={handleChange}
        required
      >
        <option value="" disabled>Select an address</option>
        {addresses.map((address) => (
          <option key={address.id} value={address.id}>
            {`${address.street}, ${address.city}, ${address.state}, ${address.country} - ${address.postalCode}`}
          </option>
        ))}
      </select>
      <div style={{ marginTop: '10px' }}>
    <Link to="/address" style={{ color: '#007bff', textDecoration: 'none' }}>
      Click here to add a new Address
    </Link>
  </div>
    </div>
    <div>
      <label>
        Event Name <span style={{ color: 'red' }}>*</span>
      </label>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        required
      />
    </div>
    <div>
      <label>
        Registration Start Date <span style={{ color: 'red' }}>*</span>
      </label>
      <input
        type="date"
        name="registrationStartDate"
        value={formData.registrationStartDate}
        onChange={handleChange}
        required
      />
    </div>
    <div>
      <label>
        Registration End Date <span style={{ color: 'red' }}>*</span>
      </label>
      <input
        type="date"
        name="registrationEndDate"
        value={formData.registrationEndDate}
        onChange={handleChange}
        required
      />
    </div>
    <div>
      <label>
        Event Start Date <span style={{ color: 'red' }}>*</span>
      </label>
      <input
        type="date"
        name="eventStartDate"
        value={formData.eventStartDate}
        onChange={handleChange}
        required
      />
    </div>
    <div>
      <label>
        Event End Date <span style={{ color: 'red' }}>*</span>
      </label>
      <input
        type="date"
        name="eventEndDate"
        value={formData.eventEndDate}
        onChange={handleChange}
        required
      />
    </div>
    <div>
      <label>
        Registration Fees <span style={{ color: 'red' }}>*</span>
      </label>
      <input
        type="number"
        name="registrationFees"
        value={formData.registrationFees}
        onChange={handleChange}
        required
      />
    </div>
    <div>
      <label>
        Event Description <span style={{ color: 'red' }}>*</span>
      </label>
      <textarea
        name="eventDescription"
        value={formData.eventDescription}
        onChange={handleChange}
        required
      />
    </div>
    <button type="submit">Submit</button>
  </form>
</div>


      <div className="event-list">
        <h2 className="create-event-heading">All Events</h2>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Registration Start</th>
              <th>Registration End</th>
              <th>Event Start</th>
              <th>Event End</th>
              <th>Fees</th>
              <th>Description</th>
              <th>Venue</th>
              <th>Category</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event) => (
              <tr key={event.id}>
                <td>{event.name}</td>
                <td>{event.registrationStartDate}</td>
                <td>{event.registrationEndDate}</td>
                <td>{event.eventStartDate}</td>
                <td>{event.eventEndDate}</td>
                <td>{event.registrationFees}</td>
                <td>{event.eventDescription}</td>
                <td>
                  {event.venueStreet}, {event.venueCity}, {event.venueState},
                  {event.venueCountry}, {event.venuePostalCode}
                </td>
                <td>
                  {event.categoryName} ({event.categoryMinAge}-{event.categoryMaxAge} years, {event.categoryGender})
                </td>
                <td>
                  <button
                    className="delete-button"
                    onClick={() => handleDeleteClick(event)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {eventToDelete && (
        <div className="confirmation-popup">
          <div className="popup-content">
            <p>Are you sure you want to delete this age category?</p>
            <button onClick={() => confirmDelete(eventToDelete)}>Yes</button>
            <button onClick={() => setEventToDelete(null)}>No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateEvents;

// import React, { useState } from "react";
// import { Auth } from "aws-amplify";
// import "./CreateEvents.css";
// import axiosInstance from "../../axiosConfig";
// import { toast } from 'react-toastify';

// const CreateEvents = () => {
//   const [file, setFile] = useState(null);
//   const [name, setName] = useState("");
//   const [category, setCategory] = useState("");
//   const [date, setDate] = useState("");
//   const [time, setTime] = useState("");
//   const [place, setPlace] = useState("");

//   const categories = [
//     "U8",
//     "U10",
//     "U12",
//     "U14",
//     "U16",
//     "U18",
//     "U8 Male",
//     "U8 Female",
//     "U10 Male",
//     "U10 Female",
//     "U12 Male",
//     "U12 Female",
//     "30+ Male",
//     "30+ Female",
//     "30+ All",
//     "40+ Male",
//     "40+ Female",
//     "40+ All",
//     "50+ Male",
//     "50+ Female",
//     "50+ All",
//     "60+ Male",
//     "60+ Female",
//     "60+ All",
//   ];

//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const handleEventNameChange = (e) => {
//     setName(e.target.value);
//   };

//   const handleCategoryChange = (e) => {
//     setCategory(e.target.value);
//   };

//   const handleDateChange = (e) => {
//     setDate(e.target.value);
//   };

//   const handleTimeChange = (e) => {
//     setTime(e.target.value);
//   };

//   const handlePlaceChange = (e) => {
//     setPlace(e.target.value);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const metadata = {
//       name,
//       category,
//       date,
//       time,
//       place,
//     };

//     const formData = new FormData();
//     formData.append("file", file);
//     formData.append(
//       "metadata",
//       new Blob([JSON.stringify(metadata)], { type: "application/json" })
//     );

//     try {
//       const session = await Auth.currentSession();
//       const token = session.getIdToken().getJwtToken();

//       const response = await axiosInstance.post("/events", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       console.log("File uploaded successfully:", response.data);
//       toast.success("Event Uploaded Successfully");
//     } catch (error) {
//       console.error("Error uploading file:", error);
//       toast.error("Error uploading file:", error);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <div>
//         <label>Event Name:</label>
//         <input type="text" value={name} onChange={handleEventNameChange} />
//       </div>
//       <div className="dropdown-container">
//         <label>Category:</label>
//         <select value={category} onChange={handleCategoryChange}>
//           <option value="">Select a category</option>
//           {categories.map((cat, index) => (
//             <option key={index} value={cat}>
//               {cat}
//             </option>
//           ))}
//         </select>
//       </div>
//       <div>
//         <label>Date:</label>
//         <input type="date" value={date} onChange={handleDateChange} />
//       </div>
//       <div>
//         <label>Time:</label>
//         <input type="time" value={time} onChange={handleTimeChange} />
//       </div>
//       <div>
//         <label>Place:</label>
//         <input type="text" value={place} onChange={handlePlaceChange} />
//       </div>
//       <div>
//         <label>File:</label>
//         <input type="file" onChange={handleFileChange} />
//       </div>
//       <button type="submit">Upload</button>
//     </form>
//   );
// };

// export default CreateEvents;
