import React from "react";
import "./AcademySubContainer.css";

const BasketballCurriculum = () => {
  const imgsrc1 = `${process.env.PUBLIC_URL}/academy/T1.png`;
  const imgsrc2 = `${process.env.PUBLIC_URL}/academy/T2.png`;
  const imgsrc3 = `${process.env.PUBLIC_URL}/academy/T4.png`;
  return (
    <div>
      <div className="academy-sub-container">
        <div className="academy-image">
        <img className="gameday" src={imgsrc3} alt="MBA Logo" />
          <img className="gameday" src={imgsrc2} alt="MBA Logo" />
          {/* <img className="gameday" src={imgsrc1} alt="MBA Logo" /> */}
        </div>
        <div className="academy-sub-content">
          <h3>Basketball Curriculum</h3>
          <p>
            The MASTERS BASKETBALL ACADEMY has developed a curriculum to help
            players, parents, coaches, and organizations better understand the
            process of improvement.
          </p>
          <p>
            The curriculum is a developmentally-based progression that depends
            on players mastering new skills before advancing to the next level.
            It’s not based on a player’s age, but rather on his or her
            proficiency at key skills.
          </p>
          <p>
            In the first four levels of the curriculum, coaches will focus on
            teaching student-athletes the basic fundamentals of the game of
            basketball, and will use a skill progression checklist that helps
            players advance through developmental levels. As they progress
            through each level, players will be challenged by increasing the
            level of difficulty and intensity of the drills. This approach helps
            players to continually develop and enjoy the positive reinforcement
            of mastering new skills.
          </p>
          <p>
            After completing the first four levels, student-athletes advance to
            an elite level of player development. This second stage focuses on
            the advancement and mastery of basketball skills learned throughout
            the first four stages, while placing significantly more focus on
            team concepts and competition.
          </p>
          <p>
            Coaches will introduce more complex basketball techniques, concepts
            and tactics that are commonly used in elite high school and college
            programs.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BasketballCurriculum;
