import React from 'react';

const Mission = () => {
  return (
    <div className="mba-container">
    <div className="mba-content">
      <h2>Mission</h2>
      {/* <p>MASTERS BASKETBALL ACADEMY aims to create a safe and enjoyable environment for Coloradans to play, learn, and build lasting relationships. We identify talent through local associations and schools, leveraging partnerships to enhance our mobile basketball training application, Home Court, and other national development programs.</p> */}
     <p>Connect all Coloradans with a safe and enjoyable environment where they can play, learn, and build lasting relationships through the Masters Basketball Academy.</p>
    </div>
    </div>
  );
};

export default Mission;
