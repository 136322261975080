import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom'; // Import useParams to get eventId from the URL
import axiosInstance from '../../axiosConfig';
import TeamCard from '../userDashboard/TeamCard';
import { Auth } from 'aws-amplify';

const AllTeams = () => {
  const location = useLocation(); // Use location to access the passed state
  const { eventId } = location.state || {};

  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null); 
  const navigate = useNavigate();

  useEffect(() => {
    console.log("event id" + eventId);
    const fetchTeamsForEvent = async () => {
      try {
        setLoading(true);
        console.log("event id" + eventId);
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const response = await axiosInstance.get(`/admin/event/${eventId}/teams`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
        setTeams(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching teams:', error);
        setLoading(false);
      }
    };

    fetchTeamsForEvent();
  }, [eventId]);

  if (loading) {
    return (
      <div className="loading-overlay">
        <div className="loading-text">Loading, please wait...</div>
      </div>
    );
  }

  const handleViewDetails = (teamId) => {
    setSelectedTeamId(teamId);
    console.log("Selected Team ID:", teamId);
    navigate('/team/details', { state: { teamId } });
  };

  return (
    <div className="home">
    <div style={{ height: "10vh" }} className="top-heading">
    <h1>Teams for Event</h1>
    </div>
    <div className="teams-container">
      {/* <h2>Teams for Event </h2> */}
      <div className="team-list">
        {teams.length > 0 ? (
          teams.map((team) => <TeamCard key={team.id} team={team} onViewDetails={handleViewDetails}/>)
        ) : (
          <p>No teams registered for this event.</p>
        )}
      </div>
      {selectedTeamId && (
        <div className="team-details">
          <h3>Selected Team ID: {selectedTeamId}</h3>
          {/* You can add more logic to display full details of the selected team */}
        </div>
      )}
      {/* <ul>
        {teams.length > 0 ? (
          teams.map((team) => (
            <li key={team.id}>{team.name}</li>
          ))
        ) : (
          <p>No teams registered for this event.</p>
        )}
      </ul> */}
    </div>
    </div>
  );
};

export default AllTeams;
