import React from 'react';
import './EventCard.css'; // Optional: Create this file to add styles

const EventCard = ({ event }) => {
  return (
    <div className='event-card-container'>
    <div className="event-card">
      <h3>{event.eventName}</h3>
      <p><strong>Start Date:</strong> {new Date(event.startDate).toLocaleDateString()}</p>
      <p><strong>End Date:</strong> {new Date(event.endDate).toLocaleDateString()}</p>
      <p><strong>Event Venue:</strong></p>
      <div className='player-register-player-address'>
      <p>{event.eventAddress.street},</p>
      <p>{event.eventAddress.city},{event.eventAddress.state}</p>
      <p>{event.eventAddress.country},{event.eventAddress.postalCode}</p>
      </div>
    </div>
    </div>
  );
};

export default EventCard;
