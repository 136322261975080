// src/components/auth/RedirectAuthenticated.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const RedirectAuthenticated = ({ isAuthenticated, children }) => {
  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
};

export default RedirectAuthenticated;
