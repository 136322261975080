import React, { createContext, useState, useContext, useEffect } from 'react';

const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
  const [time, setTime] = useState(() => parseInt(localStorage.getItem('time')) || 600); // 10 minutes
  const [isRunning, setIsRunning] = useState(() => JSON.parse(localStorage.getItem('isRunning')) || false);
  const [period, setPeriod] = useState(() => parseInt(localStorage.getItem('period')) || 1);
  const [timer14, setTimer14] = useState(() => parseInt(localStorage.getItem('timer14')) || 14);
  const [timer24, setTimer24] = useState(() => parseInt(localStorage.getItem('timer24')) || 24);
  const [isRunning14, setIsRunning14] = useState(() => JSON.parse(localStorage.getItem('isRunning14')) || false);
  const [isRunning24, setIsRunning24] = useState(() => JSON.parse(localStorage.getItem('isRunning24')) || false);

  useEffect(() => {
    let timer, timer14Interval, timer24Interval;;
    if (isRunning && time > 0) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
        // setPeriod(period);
      }, 500);
    } else if (time === 0) {
      // setPeriod((prevPeriod) => prevPeriod + 1);
      setPeriod((prevPeriod) => {
        if (prevPeriod === 1) {
          return 2; // Set the period to 2 if it's 1
        } else {
          return prevPeriod + 1; // Otherwise, keep incrementing
        }
      });
      setTime(600); // Reset to 10 minutes
      setIsRunning(false); // Stop the timer after the period ends
    }

    // if (isRunning14 && timer14 > 0) {
    //     timer14Interval = setInterval(() => {
    //       setTimer14((prevTime) => prevTime - 1);
    //     }, 1000);
    //   } else if (timer14 === 0) {
    //     setIsRunning14(false);
    //   }
  
    //   if (isRunning24 && timer24 > 0) {
    //     timer24Interval = setInterval(() => {
    //       setTimer24((prevTime) => prevTime - 1);
    //     }, 1000);
    //   } else if (timer24 === 0) {
    //     setIsRunning24(false);
    //   }
    return () => {
      if (timer) clearInterval(timer);
    };
    // return () => clearInterval(timer);
    clearInterval(timer14Interval);
      clearInterval(timer24Interval);
  }, [isRunning, time, isRunning14, timer14, isRunning24, timer24]);

  useEffect(() => {
    localStorage.setItem('time', time);
  }, [time]);

  useEffect(() => {
    localStorage.setItem('isRunning', isRunning);
  }, [isRunning]);

  useEffect(() => {
    localStorage.setItem('period', period);
  }, [period]);

  useEffect(() => {
    localStorage.setItem('timer14', timer14);
  }, [timer14]);

  useEffect(() => {
    localStorage.setItem('isRunning14', isRunning14);
  }, [isRunning14]);

  useEffect(() => {
    localStorage.setItem('timer24', timer24);
  }, [timer24]);

  useEffect(() => {
    localStorage.setItem('isRunning24', isRunning24);
  }, [isRunning24]);

  const handleStartTimer = () => {
    if (!isRunning) {
      setIsRunning(true);
    }
  };

  const handlePauseTimer = () => {
    setIsRunning(false);
  };

  const handleResetTimer = () => {
    setIsRunning(false);
    setTime(600);
  };
  
  const handleResetPeriod = () =>{
    setPeriod(1);
  }

  const handleIncreaseTime = () => {
    setTime((prevTime) => prevTime + 60);
  };

  const handleDecreaseTime = () => {
    if (time > 60) {
      setTime((prevTime) => prevTime - 60);
    }
  };

  const handleStartTimer14 = () => {
    setTimer14(14);
    setIsRunning14(true);
  };

  const handleStartTimer24 = () => {
    setTimer24(24);
    setIsRunning24(true);
  };

  const handleTimer = () => {
    setIsRunning((prevIsRunning) => !prevIsRunning);
  };

  return (
    <TimerContext.Provider
      value={{
        time,
        period,
        isRunning,
        handleStartTimer,
        handlePauseTimer,
        handleResetTimer,
        handleResetPeriod,
        handleIncreaseTime,
        handleDecreaseTime,
        handleTimer,
        timer14,
        timer24,
        isRunning14,
        isRunning24,
        handleStartTimer14,
        handleStartTimer24,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};

export const useTimer = () => useContext(TimerContext);
