import React from "react";

const Organisation = () => {
  return (
    <div className='home'>
    <div className='top-heading'>
      <h1>Organisation</h1>
    </div>
    <div className="mba-container">
      <div className="mba-content">
        <h3>The MASTERS BASKETBALL ACADEMY</h3>
        <h4>
          Masters Basketball Academy is the Registered organisation as per the
          Karnataka Societies Registration Act, 1960 - Registration Number:
          DRB2/SOR/13/2022-2023
        </h4>
        <p>
          MBA shall be managed by a office Bearers , consisting of a minimum of
          nine members and a maximum of twenty two members, as follows:
        </p>
        <ul>
          <li>
            One President proposed by either a Member of MBA (IF) or any member
            of the Board and approved by the AGM
          </li>
          <li>
            Two Vice Presidents selected amongst the Board members by the Board,
            and approved by the AGM.
          </li>
          <li>
            One General Secretary selected amongst the Board members by the
            Board, and approved by the AGM.
          </li>
          <li>
            One Treasurer selected amongst the Board members, shall have the
            requisite financial expertise to exercise oversight responsibility
            over the MBA’s financial operations and shall be selected amongst
            the Board members to do so, and approved by the AGM.
          </li>
          <li>
            Four members are selected as technical committee - shall have the
            requisite technical expertise to exercise Coaching, Conducting the
            tournaments - shall be selected amongst the Board members to do so,
            and approved by the AGM.
          </li>
          <li>
            Four members are selected as Advisory committee - shall have the
            legal technical expertise to exercise Day-to-day activities - shall
            be selected amongst the Board members to do so, and approved by the
            AGM.
          </li>
          <li>Eight Members are selected as founding members of MBA.</li>
        </ul>
        <p>The Hon- President of the MBA is ex officio, without a vote.</p>
        <p>
          The members of the MBA Board shall each be elected for a Three -year
          term. They can serve a maximum of three terms.
        </p>
      </div>
    </div>
    </div>
  );
};

export default Organisation;
