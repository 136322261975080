import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AddressPage.css';
import axiosInstance from '../../../axiosConfig';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';

const AddressPage = () => {
  const [form, setForm] = useState({
    street: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
  });

  const [addresses, setAddresses] = useState([]);
  const [addressToDelete, setAddressToDelete] = useState(null);

  // Fetch existing addresses from the backend when the component mounts
  useEffect(() => {
    fetchAddresses();
  }, []);

  const fetchAddresses = async () => {
    try {
      const response = await axiosInstance.get('/addresses'); 
      setAddresses(response.data);
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      await axiosInstance.post('/addresses', form, {
        headers: {
            'Content-Type': 'application/json',
             Authorization: `Bearer ${token}`
        },
    }); // Replace with your backend API endpoint
      fetchAddresses(); // Refresh the address list after adding a new one
      setForm({
        street: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
      });
      toast.success("Address added successfully.");
    } catch (error) {
      console.error('Error adding address:', error);
      toast.error('Error adding address:', error);
    }
  };

  const handleDeleteClick = (address) => {
    setAddressToDelete(address);
  };

  const confirmDelete = async () => {
    if (addressToDelete) {
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        await axiosInstance.delete(`/addresses/${addressToDelete.id}`, {
          headers: {
              'Content-Type': 'application/json',
               Authorization: `Bearer ${token}`
          },
      }); // Replace with your backend API endpoint
        fetchAddresses(); // Refresh the address list after deleting
        setAddressToDelete(null);
        toast.success("Address deleted successfully.");
      } catch (error) {
        if(error.response && error.response.data){
        console.error('Error deleting address: '+  error.response.data);
        toast.error('Error deleting address: '+ error.response.data);
        }
        else{
          console.error('Error deleting address.', error);
          toast.error('Error deleting address.', error);
        }
      }
    }
  };

  return (
    <div className="address-page">
      {/* Part 1: Add New Address */}
      <div className="add-address">
        <h2>Add New Address</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Street <span style={{ color: "red" }}>*</span></label>
            <input
              type="text"
              name="street"
              value={form.street}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>City <span style={{ color: "red" }}>*</span></label>
            <input
              type="text"
              name="city"
              value={form.city}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>State <span style={{ color: "red" }}>*</span></label>
            <input
              type="text"
              name="state"
              value={form.state}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Country <span style={{ color: "red" }}>*</span></label>
            <input
              type="text"
              name="country"
              value={form.country}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Postal Code <span style={{ color: "red" }}>*</span></label>
            <input
              type="text"
              name="postalCode"
              value={form.postalCode}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>

      {/* Part 2: Display All Addresses */}
      <div className="address-list">
        <h2>All Addresses</h2>
        {addresses.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Street</th>
                <th>City</th>
                <th>State</th>
                <th>Country</th>
                <th>Postal Code</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {addresses.map((address) => (
                <tr key={address.id}>
                  <td>{address.street}</td>
                  <td>{address.city}</td>
                  <td>{address.state}</td>
                  <td>{address.country}</td>
                  <td>{address.postalCode}</td>
                  <td>
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteClick(address)}
                    >
                      🗑️ Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No addresses found.</p>
        )}
      </div>

      {/* Confirmation Popup */}
      {addressToDelete && (
        <div className="confirmation-popup">
          <div className="popup-content">
            <p>Are you sure you want to delete this address?</p>
            <button onClick={confirmDelete}>Yes</button>
            <button onClick={() => setAddressToDelete(null)}>No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressPage;
