import React from 'react';
import './MemberCard.css';

const MemberCard = ({ member }) => {
  // Determine if the card should have special styling
  const isFoundingMember = member.occupation === 'Founding Members';
  
  return (
    <li className={`member-card ${isFoundingMember ? 'founding-member' : ''}`}>
      <img
        className="member-photo"
        src={`data:image/jpeg;base64,${member.photo}`}
        alt={`${member.name}`}
      />
      <div className="member-details">
        <h2 className={isFoundingMember ? 'centered-name' : ''}>{member.name}</h2>
        {/* Conditionally render achievements */}
        {/* {member.occupation !== 'Founding Members' && ( */}
          <p className="member-achievements">{member.achievements}</p>
         {/* )} */}
        {/* Additional member details */}
        {/* <p>Age: {member.age}</p> */}
        {/* <p>Profession: {member.profession}</p>
        <p>Occupation: {member.occupation}</p> */}
      </div>
    </li>
  );
};

export default MemberCard;
