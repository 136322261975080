import React, { useState } from "react";
import "./EventDetsils.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";
import axiosInstance from "../../axiosConfig";
import CustomAlert from "../CustomAlert";

const EventDetails = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate(); 
  const event = state?.event; 
  const [isReadAllChecked, setIsReadAllChecked] = useState(false);
  const [isGuidelineChecked, setIsGuidelineChecked] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [onConfirmAction, setOnConfirmAction] = useState(null);
  

  if (!event) return <div>No event details available</div>;
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (name === 'readAll') {
      setIsReadAllChecked(checked);
    } else if (name === 'guideline') {
      setIsGuidelineChecked(checked);
    }
  };

  const handleRegisterClick = async () => {
    const registrationStartDate = new Date(event.registrationStartDate); 
    const registrationEndDate = new Date(event.registrationEndDate);

    const today = new Date();
    today.setHours(0, 0, 0, 0);  
    registrationStartDate.setHours(0, 0, 0, 0);  
    registrationEndDate.setHours(0, 0, 0, 0);

    if (registrationStartDate >today) {
      toast.warn("Registration will start from " + registrationStartDate.toLocaleDateString());
      return;
    }

    if (registrationEndDate < today) {
      toast.warn("Registration has already ended on " + registrationEndDate.toLocaleDateString());
      return;
    }
    
    try {
      // Check if user is logged in
      const user = await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const statusResponse = await axiosInstance.get("/team/status", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      // setStatus(statusResponse.data);
      const status = statusResponse.data;
      console.log("status - " + status);
      if(status === 'Start Registration.' || status === 'Registration successful.'){
        navigate('/events/register', { state: { event } });
      }else{
        if(status === 'Payment is under review.') {
          setAlertMessage('Your previous payment is currently under review. You may only register another team once the verification process for the previous team is complete.');
        }
        if(status === 'Payment not completed.') {
          setAlertMessage('It looks like you have an incomplete payment. Please complete the pending payment before proceeding with another registration.');
        }
        if(status === 'Payment failed.') {
          setAlertMessage('It looks like your payment is failed. Please retry the payment.');
        }
        if(status === 'No players found in your team.') {
          setAlertMessage('Your team registration is incomplete as no players have been added. Please complete the team registration before starting a new one.');
        }
        
        // setAlertMessage(status);
        setOnConfirmAction(() => {
          return () => navigate('/profile'); // Define a single function to be executed on confirmation
        });
        
        setShowAlert(true);

      }
      
    } catch (error) {
      setAlertMessage("Please log in before proceeding with the registration.");
      setOnConfirmAction(() => {
        return () => navigate('/login'); // Define a single function to be executed on confirmation
      });
      setShowAlert(true);
    }
};

  const isRegisterButtonDisabled = !(isReadAllChecked && isGuidelineChecked);

  const handleFileClick = (fileName) => {
    const fileUrl = `${process.env.PUBLIC_URL}/${fileName}`;
    window.open(fileUrl, '_blank'); // Opens the PDF in a new tab
  };

  return (
    <div className="home">
      <div className="top-heading">
        <h1>Event Details</h1>
      </div>
      <div className="event-detail-container">
        <div className="event-detail-content">
          <div className="event-details">
            {/* General Info */}
            <div className="section general-info">
              <h2 className="section-title">General Information</h2>
              <p>
                <strong>Event Name:</strong> {event.name}
              </p>
              <p>
                <strong>Registration Dates:</strong>{" "}
                {event.registrationStartDate} to {event.registrationEndDate}
              </p>
              <p>
                <strong>Event Dates:</strong> {event.eventStartDate} to{" "}
                {event.eventEndDate}
              </p>
              <p>
                <strong>Age Category:</strong> {event.categoryMinAge} -{" "}
                {event.categoryMaxAge} years
              </p>
            </div>

            {/* Location Details */}
            <div className="section location-details">
              <h2 className="section-title">Location Details</h2>
              <p>
                <strong>Street:</strong> {event.venueStreet}
              </p>
              <p>
                <strong>City:</strong> {event.venueCity}
              </p>
              <p>
                <strong>State:</strong> {event.venueState}
              </p>
              <p>
                <strong>Country:</strong> {event.venueCountry}
              </p>
              <p>
                <strong>Postal Code:</strong> {event.venuePostalCode}
              </p>
            </div>

            {/* Event Description */}
            {event.eventDescription && (
              <div className="section event-description">
                <h2 className="section-title">Event Description</h2>
                <p>{event.eventDescription}</p>
              </div>
            )}

             {/* Guidelines Section */}
      <div className="section guidelines">
        <h2 className="section-title">Guidelines</h2>
        <div className="checkbox-group">
          <label>
            <input
              type="checkbox"
              name="readAll"
              checked={isReadAllChecked}
              onChange={handleCheckboxChange}
              />
              <span>Read All Information and Guideline for Event</span>
              <a 
                href="#0" 
                onClick={() => handleFileClick('TermsAndConditions.pdf')}
                style={{ marginLeft: '10px', color: '#007bff', textDecoration: 'underline', cursor: 'pointer' }}
              >
                (View Terms)
              </a>
          </label>
          <label>
            <input
              type="checkbox"
              name="guideline"
              checked={isGuidelineChecked}
              onChange={handleCheckboxChange}
                />
                <span>Guideline for Registration</span>
                <a 
                  href="#0" 
                  onClick={() => handleFileClick('GuidelineForRegistration.pdf')}
                  style={{ marginLeft: '10px', color: '#007bff', textDecoration: 'underline', cursor: 'pointer' }}
                >
                  (View Guideline)
                </a>
              </label>
        </div>
        <button
          className="register-button"
          disabled={isRegisterButtonDisabled}
          onClick={handleRegisterClick} 
        // onClick={() => alert('Registered successfully!')} 
        >
          Register
        </button>
          </div>
          </div>
        </div>
      </div>
      <CustomAlert 
        show={showAlert} 
        message={alertMessage} 
        onClose={() => setShowAlert(false)} 
        onConfirm={onConfirmAction}  // Pass the confirm action (if any)
      />
    </div>
  );
};

export default EventDetails;
