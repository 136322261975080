import React, { useState, useEffect } from "react";
import "./Display.css";
import useLocalStorageSync from "./useLocalStorageSync";
import { useTimer } from "./TimerContext";
import { Link } from "react-router-dom";

const Display = () => {
  const homeScore = useLocalStorageSync("HomeScore", 0);
  const awayScore = useLocalStorageSync("AwayScore", 0);
  const matchNo = useLocalStorageSync("MatchNo", 0);
  const matchName = useLocalStorageSync("MatchName", "");
  const homeTeamName = useLocalStorageSync("homeTeamName", "");
  const awayTeamName = useLocalStorageSync("awayTeamName", "");
  const homeTeamOut = useLocalStorageSync("homeTeamOut", "");
  const awayTeamOut = useLocalStorageSync("awayTeamOut", "");
  const homeTeamFoul = useLocalStorageSync("homeTeamFoul", "");
  const awayTeamFoul = useLocalStorageSync("awayTeamFoul", "");
  const homePlayerNo = useLocalStorageSync("homePlayerNo", "");
  const awayPlayerNo = useLocalStorageSync("awayPlayerNo", "");
  const homePlayerFoul = useLocalStorageSync("homePlayerFoul", "");
  const awayPlayerFoul = useLocalStorageSync("awayPlayerFoul", "");
  const activeArrow = useLocalStorageSync("activeArrow", "");
  const { timer14, timer24, isRunning14, isRunning24 } = useTimer();

  const [glowLeft, setGlowLeft] = useState(false);
//   const [glowRight, setGlowRight] = useState(false);

  const toggleGlowLeft = () => setGlowLeft(!glowLeft);
//   const toggleGlowRight = () => setGlowRight(!glowRight);

  const [time, setTime] = useState(
    () => parseInt(localStorage.getItem("time")) || 600
  );
  const [isRunning, setIsRunning] = useState(
    () => JSON.parse(localStorage.getItem("isRunning")) || false
  );
//   const [period, setPeriod] = useLocalStorageSync("period", "1");
  const [period, setPeriod] = useState(
    () => parseInt(localStorage.getItem("period")) || 1
  );
//   useEffect(() => {
//     // This will run when the component mounts or when the period state changes
//     localStorage.setItem("period", period);
// }, [period]);

//   const [glowRight, setGlowRight] = useState(false);

//   const toggleGlowRight = () => {
//     setGlowRight(!glowRight);
//   };

//   useEffect(() => {
//     const handleStorageChange = () => {
//       setTime(parseInt(localStorage.getItem("time")) || 600);
//       setIsRunning(JSON.parse(localStorage.getItem("isRunning")) || false);
//       setPeriod(parseInt(localStorage.getItem("period")) || 1);
//     };

//     window.addEventListener("storage", handleStorageChange);

//     return () => window.removeEventListener("storage", handleStorageChange);
//   }, []);
useEffect(() => {
    const interval = setInterval(() => {
      const newTime = parseInt(localStorage.getItem("time")) || 600;
      setTime(newTime);
    }, 500); 
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const newPeriod = parseInt(localStorage.getItem("period")) || 1;
      setPeriod(newPeriod);
    }, 500);
    return () => clearInterval(interval);
  }, []);
  
  

  return (
    <div className="display-home">
      {/* <h1>Count in Display:</h1>
      <p>Count: {count}</p> */}
      <div className="display-header">
        <div className="matchNo-box">
          <div className="matchNo">
            <h1 className="matchNo-text">MatchNo</h1>
            <h1 className="matchNo-text">{matchNo}</h1>
          </div>
        </div>
        <div className="matchName-box ">
          <div className="matchName">
            <h1 className="matchName-text">{matchName}</h1>
          </div>
        </div>
        <div className="logo-outer-box">
          <div className="logo-box ">
            <img src="/logo.png" alt="netflix" className="logo " />
          </div>
        </div>
      </div>

      <div className="display-body">
        <div className="home-away-team ">
          <div className="team-name-box">
            <h1 className="team-name ">{homeTeamName}</h1>
          </div>
          <div className="score-container ">
            <h1 className="score-text">SCORE</h1>
            <div className="score-box">
              <h1 className="score">{homeScore}</h1>
            </div>
          </div>
          {/* <div className="score-buttons"></div> */}
          <div className="team-out-container ">
            <h1 className="team-out-text">TEAM OUT</h1>
            <div className="team-out-box ">
              <h1 className="team-out">{homeTeamOut}</h1>
            </div>
          </div>
          <div className="team-foul-container ">
            <h1 className="team-foul-text ">TEAM FOUL</h1>
            <div className="team-foul-box">
              <h1 className="team-foul">{homeTeamFoul}</h1>
            </div>
          </div>
          <div className="fo-container">
            <div className="player-fo-container-left ">
              <h1 className="player-fo-text">PLAYER NO.</h1>
              <div className="player-fo-box">
                <h1 className="player-fo">{homePlayerNo}</h1>
              </div>
            </div>
            <div className="center-gap"></div>
            <div className="player-fo-container-right">
              <h1 className="player-fo-text  ">PLAYER FOUL</h1>
              <div className="player-fo-box">
                <h1 className="player-fo ">{homePlayerFoul}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="home-away-team">
          <div className="timer-container">
            <h1 className="timer-text">TIMER</h1>
            <div className="timer-box">
              <h1 className="timer">
                {Math.floor(time / 60)}:
                {time % 60 < 10 ? `0${time % 60}` : time % 60}
              </h1>
            </div>
          </div>
          <div className="serving-arrow-container">
            <div className="serving-arrow-container-left">
            
              <h1
                    
                    style={{
                        color: activeArrow ==="left" ? 'yellow' : 'black', // Change color based on state
                        fontSize: '50px', // Adjust size as needed
                        cursor: 'pointer' // Change cursor to pointer
                    }}
                    >
                    ⬅
                    </h1>
            </div>
            <div className="serving-arrow-center-gap"></div>
            <div className="serving-arrow-container-right">             
              <h1
                style={{
                    color: activeArrow ==="right" ? 'yellow' : 'black', // Change color based on state
                    fontSize: '50px', // Adjust size as needed
                    cursor: 'pointer', // Change cursor to pointer
                    
                }}
                >
                ➡
                </h1>
            </div>
          </div>
          <div className="timer-container">
            <h1 className="timer-text">PERIOD</h1>
            <div className="period-box">
              <h1 className="timer-period">{period}</h1>
            </div>
          </div>
          {/* <div className="timer-container">
                <h1 className="timer-text">Serving</h1>
                <div className="period-box">
                    <h1 className="timer-period"> 14 Seconds Timer: {timer14} </h1>
                    <h1>24 Seconds Timer: {timer24} </h1>
                </div>
            </div> */}
          <div className="display-back-button">
            <Link className="custom-back-button" to="/controller">
              GO TO CONTROLLER
            </Link>
          </div>
        </div>
        <div className="home-away-team ">
          <div className="team-name-box">
            <h1 className="team-name ">{awayTeamName}</h1>
          </div>
          <div className="score-container ">
            <h1 className="score-text">SCORE</h1>
            <div className="score-box">
              <h1 className="score">{awayScore}</h1>
            </div>
          </div>
          {/* <div className="score-buttons"></div> */}
          <div className="team-out-container ">
            <h1 className="team-out-text">TEAM OUT</h1>
            <div className="team-out-box ">
              <h1 className="team-out">{awayTeamOut}</h1>
            </div>
          </div>
          <div className="team-foul-container ">
            <h1 className="team-foul-text ">TEAM FOUL</h1>
            <div className="team-foul-box">
              <h1 className="team-foul">{awayTeamFoul}</h1>
            </div>
          </div>
          <div className="fo-container">
            <div className="player-fo-container-left">
              <h1 className="player-fo-text">PLAYER NO.</h1>
              <div className="player-fo-box">
                <h1 className="player-fo">{awayPlayerNo}</h1>
              </div>
            </div>
            <div className="center-gap"></div>
            <div className="player-fo-container-right">
              <h1 className="player-fo-text ">PLAYER FOUL</h1>
              <div className="player-fo-box">
                <h1 className="player-fo ">{awayPlayerFoul}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Display;
