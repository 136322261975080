import React, { useState } from 'react';
import './EventGallery.css';

const EventGallery = ({ event, onBack }) => {
  const [enlargedImage, setEnlargedImage] = useState(null);

  const photos = Array.from({ length: 6 }, (_, index) => ({
    id: index + 1,
    src: `${process.env.PUBLIC_URL}/gallery/${event}/${index + 1}.jpeg`
  }));

  const handleImageClick = (photo) => {
    setEnlargedImage(photo.src);
  };

  const handleCloseEnlarged = () => {
    setEnlargedImage(null);
  };

  return (
    <div className="event-gallery-container">
      <button onClick={onBack}>Back to Gallery</button>
      <div className="event-gallery">
        {photos.map((photo) => (
          <div
            key={photo.id}
            className="event-photo-item"
            onClick={() => handleImageClick(photo)}
          >
            <img src={photo.src} alt={`Photo ${photo.id}`} />
          </div>
        ))}
      </div>
      {enlargedImage && (
        <div className="overlay" onClick={handleCloseEnlarged}>
          <img src={enlargedImage} alt="Enlarged" className="enlarged-image" />
        </div>
      )}
    </div>
  );
};

export default EventGallery;
